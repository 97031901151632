import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// This is a completely standalone admin access component
const AdminPage = () => {
  const [diagnosticExams, setDiagnosticExams] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    document.title = "Admin Access";
    
    // Prevent the standard app from redirecting
    const originalPushState = window.history.pushState;
    window.history.pushState = function() {
      // Block any redirect attempts
      return originalPushState.apply(this, arguments);
    };
    
    const fetchDiagnosticExams = async () => {
      try {
        const jwt = localStorage.getItem('jwt');
        const response = await fetch('https://api.getmina.co/api/v1/panel/teacher/diagnostic-exams', {
          method: 'GET',
          headers: { 'Authorization': `Bearer ${jwt}` }
        });
        
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        
        const data = await response.json();
        setDiagnosticExams(data);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError('Failed to fetch diagnostic exams. Please check your connection and try again.');
      }
      setLoading(false);
    };

    fetchDiagnosticExams();
    
    // Clean up
    return () => {
      window.history.pushState = originalPushState;
    };
  }, []);

  if (loading) {
    return <div style={{ padding: '20px', textAlign: 'center' }}>Loading...</div>;
  }

  if (error) {
    return (
      <div style={{ padding: '20px', textAlign: 'center', color: 'red' }}>
        <h2>Error</h2>
        <p>{error}</p>
        <button onClick={() => window.location.reload()}>Try Again</button>
      </div>
    );
  }

  // Simple inline styles
  const styles = {
    container: {
      maxWidth: '800px',
      margin: '0 auto',
      padding: '20px',
      fontFamily: 'Arial, sans-serif',
    },
    header: {
      backgroundColor: '#f5f5f5',
      padding: '15px',
      borderRadius: '5px',
      marginBottom: '20px',
    },
    title: {
      fontSize: '24px',
      margin: '0 0 10px 0',
    },
    subtitle: {
      fontSize: '16px',
      color: '#666',
      margin: '0',
    },
    examList: {
      listStyle: 'none',
      padding: '0',
    },
    examItem: {
      backgroundColor: 'white',
      border: '1px solid #ddd',
      borderRadius: '5px',
      marginBottom: '10px',
      overflow: 'hidden',
      transition: 'transform 0.2s',
      cursor: 'pointer',
    },
    examLink: {
      display: 'block',
      padding: '15px',
      textDecoration: 'none',
      color: 'inherit',
    },
    examLevel: {
      fontWeight: 'bold',
      marginBottom: '5px',
      display: 'block',
    },
    examDate: {
      fontSize: '14px',
      color: '#666',
    },
    noExams: {
      textAlign: 'center',
      color: '#666',
      padding: '20px',
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <h1 style={styles.title}>Teacher Admin Panel</h1>
        <p style={styles.subtitle}>View and manage diagnostic exams</p>
      </div>

      <div>
        <h2>Diagnostic Exams</h2>
        {diagnosticExams.length > 0 ? (
          <ul style={styles.examList}>
            {diagnosticExams.map((exam) => (
              <li key={exam.exam_id} style={styles.examItem}>
                <a 
                  href={`/exam/${exam.exam_id}/users`} 
                  style={styles.examLink}
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href = `/exam/${exam.exam_id}/users`;
                  }}
                >
                  <span style={styles.examLevel}>Level: {exam.level}</span>
                  <span style={styles.examDate}>Created: {new Date(exam.created_at).toLocaleDateString()}</span>
                </a>
              </li>
            ))}
          </ul>
        ) : (
          <p style={styles.noExams}>No diagnostic exams found.</p>
        )}
      </div>
    </div>
  );
};

// Standalone router that doesn't depend on App.js routing
const AdminAccess = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/admin-standalone" element={<AdminPage />} />
        <Route path="*" element={<AdminPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AdminAccess;