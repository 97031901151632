import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/Login.css';
import Logo from '../assets/images/logo.png';
import {
  Box,
  Container,
  Typography,
  Button,
  Paper,
  CssBaseline,
  Radio,
  FormControlLabel,
  RadioGroup,
  styled
} from '@mui/material';

const PageContainer = styled('div')({
  minHeight: '100vh',
  width: '100%',
  background: 'linear-gradient(135deg, #9333EA 0%, #4F46E5 100%)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  fontFamily: 'Inter, sans-serif',
});

const StepCard = styled(Paper)({
  maxWidth: '500px',
  width: 'calc(100% - 48px)',
  margin: '120px auto 40px',
  padding: '40px',
  borderRadius: '16px',
  boxShadow: '0 8px 40px rgba(0, 0, 0, 0.12)',
  boxSizing: 'border-box',
  overflow: 'visible',
  backgroundColor: '#ffffff',
  position: 'relative',
});

const StepIndicator = styled(Typography)({
  fontFamily: 'Inter, sans-serif',
  fontSize: '14px',
  fontWeight: 600,
  color: '#6B7280',
  marginBottom: '16px',
});

const ContinueButton = styled(Button)(({ disabled }) => ({
  width: '100%',
  padding: '16px',
  marginTop: '24px',
  borderRadius: '12px',
  backgroundColor: disabled ? '#E9D5FF' : '#9333EA',
  color: 'white',
  fontWeight: 600,
  fontSize: '16px',
  textTransform: 'none',
  boxShadow: disabled ? 'none' : '0 4px 6px rgba(147, 51, 234, 0.25)',
  transition: 'all 0.2s ease',
  '&:hover': {
    backgroundColor: disabled ? '#E9D5FF' : '#7928CA',
    boxShadow: disabled ? 'none' : '0 6px 10px rgba(147, 51, 234, 0.3)',
    transform: disabled ? 'none' : 'translateY(-2px)',
  },
}));

const StyledRadio = styled(Radio)({
  color: '#D1D5DB',
  '&.Mui-checked': {
    color: '#9333EA',
  },
  padding: '10px',
});

const LevelLabel = styled(Typography)({
  fontFamily: 'Inter, sans-serif',
  fontSize: '16px',
  fontWeight: 500,
  color: '#111827',
});

const StyledFormControlLabel = styled(FormControlLabel)({
  marginBottom: '8px',
  '& .MuiFormControlLabel-label': {
    fontFamily: 'Inter, sans-serif',
  },
});

function TargetLevelStep() {
  const [targetLevel, setTargetLevel] = useState(null);
  const navigate = useNavigate();

  const levels = ['N5', 'N4', 'N3', 'N2', 'N1'];

  const handleContinue = () => {
    localStorage.setItem('selectedLevel', targetLevel);
    navigate('/level-test');
  };

  const handleLevelChange = (event) => {
    setTargetLevel(event.target.value);
  };

  return (
    <PageContainer>
      <CssBaseline />
      
      <Box className="logo-container-box" sx={{ 
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        padding: '16px 24px',
        backgroundColor: 'white',
        boxShadow: '0 2px 12px rgba(0, 0, 0, 0.05)',
        display: 'flex',
        alignItems: 'center',
        zIndex: 1000,
        height: '70px'
      }}>
        <img src={Logo} alt="Mina Logo" style={{ height: '40px' }} />
      </Box>
      
      <Container maxWidth="md" sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <StepCard elevation={0}>
          <StepIndicator>Step 2 of 2</StepIndicator>
          
          <Typography 
            variant="h1" 
            sx={{ 
              fontSize: '28px', 
              fontWeight: 700, 
              color: '#111827', 
              marginBottom: '16px',
              fontFamily: 'Inter, sans-serif'
            }}
          >
            What is your target level?
          </Typography>
          
          <Typography 
            variant="body1" 
            sx={{ 
              fontSize: '16px', 
              color: '#6B7280', 
              marginBottom: '32px',
              lineHeight: 1.5,
              fontFamily: 'Inter, sans-serif'
            }}
          >
            Mina has acquired data on the most recent actual JLPTs, which will help you achieve your target score.
          </Typography>
          
          <RadioGroup
            aria-label="targetLevel"
            name="targetLevel"
            value={targetLevel}
            onChange={handleLevelChange}
          >
            {levels.map((level) => (
              <StyledFormControlLabel
                key={level}
                value={level}
                control={<StyledRadio />}
                label={<LevelLabel>Level {level}</LevelLabel>}
              />
            ))}
          </RadioGroup>
          
          <ContinueButton
            onClick={handleContinue}
            disabled={targetLevel === null}
            disableElevation
          >
            Continue
          </ContinueButton>
        </StepCard>
      </Container>
    </PageContainer>
  );
}

export default TargetLevelStep;