export const fetchUserExams = async (level, section) => {
    try {
      const jwt = localStorage.getItem('jwt');
      const response = await fetch("https://api.getmina.co/api/v1/panel/get-user-exams", {
        method: "POST",
        headers: {
          'Authorization': `Bearer ${jwt}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ level, section })
      });
      const data = await response.json();
      return Array.isArray(data) ? data : [];
    } catch (error) {
      console.error("Error fetching exams:", error);
      throw error;
    }
  };
  
  export const initiateCheckoutSession = async () => {
    try {
      const jwt = localStorage.getItem('jwt');
      const response = await fetch('https://api.getmina.co/api/v1/payment/create-checkout', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${jwt}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({})
      });
      return response.json();
    } catch (error) {
      console.error('Failed to initiate checkout:', error);
      throw error;
    }
  };

export const sendQuestionChat = async (questionContext, userInput) => {
  try {
    const jwt = localStorage.getItem('jwt');
    // Use the ask-mina-help endpoint from the backend
    const response = await fetch('https://api.getmina.co/api/v1/panel/ask-mina-help', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        question_id: questionContext.question_id,
        question_text: questionContext.question_text,
        options: questionContext.options,
        correct_answer: questionContext.correct_answer,
        explanation: questionContext.explanation || '',
        user_input: `For the question: "${questionContext.question_text}" with correct answer "${questionContext.correct_answer}", ${userInput}`
      })
    });
    
    if (!response.ok) {
      throw new Error('Failed to get response from the server');
    }
    
    // The response might be a stream or a combination of text and JSON
    const responseText = await response.text();
    
    // Check if the response text contains a JSON part (usually after the text part)
    const jsonStartIndex = responseText.indexOf('{');
    
    if (jsonStartIndex >= 0) {
      try {
        // Try to extract and parse the JSON part
        const jsonPart = responseText.substring(jsonStartIndex);
        const jsonData = JSON.parse(jsonPart);
        
        // Also extract the text part that came before the JSON (if any)
        const textPart = jsonStartIndex > 0 ? responseText.substring(0, jsonStartIndex).trim() : '';
        
        // Return the combined data
        return {
          text: textPart,
          history: jsonData.history || [],
          // Also provide in OpenAI-compatible format for the existing handler
          choices: [{
            message: {
              content: textPart
            }
          }]
        };
      } catch (e) {
        console.error('Failed to parse JSON part of response:', e);
      }
    }
    
    // If JSON parsing failed or there was no JSON, return the text as is
    return {
      text: responseText,
      choices: [{
        message: {
          content: responseText
        }
      }]
    };
  } catch (error) {
    console.error('Error in sendQuestionChat:', error);
    throw error;
  }
};

export const sendQuestionQuery = async (questionContext, userQuery) => {
  try {
    const jwt = localStorage.getItem('jwt');
    const response = await fetch('https://api.getmina.co/api/v1/panel/ask-question', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        question_id: questionContext.question_id,
        question_text: questionContext.question_text,
        options: questionContext.options,
        correct_answer: questionContext.correct_answer,
        explanation: questionContext.explanation,
        user_input: userQuery
      })
    });
    
    if (!response.ok) {
      throw new Error('Failed to get response from the server');
    }
    
    return response.json();
  } catch (error) {
    console.error('Error in sendQuestionQuery:', error);
    throw error;
  }
};