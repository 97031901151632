import React, { useState, useEffect } from 'react';
import './css/Settings.css';

function MyCheckoutComponent() {
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // Automatically initiate checkout when component loads
        initiateCheckout();
    }, []);

    const initiateCheckout = async () => {
        setLoading(true);
        try {
            const jwt = localStorage.getItem('jwt');
            if (!jwt) {
                throw new Error('You must be logged in to make a purchase');
            }

            console.log('Starting checkout process...');
            
            const response = await fetch('https://api.getmina.co/api/v1/payment/create-checkout', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${jwt}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({}) // Empty body as per the API
            });

            console.log('Checkout API response status:', response.status);
            
            const data = await response.json();
            console.log('Checkout API response data:', data);

            // Use the same Stripe key that's used in App.js
            const stripeKey = 'pk_test_51PCLezBQK9NeVKEQ9UZaCLagoap3lORt7iIs0j2mZAmnVBCHh1D3hYr8h9A7UlXVRG8w1PVdaA92qxLDZjOuLRCn002sOLsGHy';
            const stripe = window.Stripe(stripeKey);
            
            if (response.ok) {
                // Handle any possible response format (sessionId or id)
                const sessionId = data.sessionId || data.id || null;
                
                if (sessionId) {
                    console.log('Successfully created checkout session:', sessionId);
                    
                    // Redirect to Stripe checkout
                    console.log('Redirecting to Stripe checkout...');
                    const result = await stripe.redirectToCheckout({
                        sessionId: sessionId
                    });
                    
                    if (result.error) {
                        console.error('Stripe redirect error:', result.error);
                        throw new Error(result.error.message);
                    }
                } else {
                    console.error('No session ID found in response:', data);
                    throw new Error('No session ID found in the API response');
                }
            } else {
                console.error('API error:', data);
                throw new Error(data.error || 'Failed to create checkout session');
            }
        } catch (error) {
            console.error('Checkout error:', error);
            setError(error.message || 'An unexpected error occurred');
            setLoading(false);
        }
    };

    return (
        <div className="settings-section">
            <h2>Premium Subscription</h2>
            
            {!loading && !error && (
                <p>Preparing your checkout experience...</p>
            )}
            
            {loading && !error && (
                <div className="settings-loading">
                    <div className="loading-spinner"></div>
                    <p>Redirecting to secure payment...</p>
                </div>
            )}
            
            {error && (
                <div className="error-message">
                    <p style={{ color: 'red' }}>Error: {error}</p>
                    <div style={{ marginTop: '20px', display: 'flex', gap: '10px' }}>
                        <button 
                            className="save-btn"
                            onClick={initiateCheckout}
                        >
                            Try Again
                        </button>
                        <button 
                            className="cancel-btn" 
                            onClick={() => window.location.href = '/'}
                        >
                            Back to Dashboard
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default MyCheckoutComponent;
