// QuestionDisplay.js
import React, { useEffect } from 'react';
import './QuestionDisplay.css';

// Question types constants
export const QUESTION_TYPES = {
  SINGLE: 'single',
  MULTIPLE: 'multiple'
};

const QuestionDisplay = ({
  type = QUESTION_TYPES.SINGLE,
  title,
  instruction,
  script,
  questionText,
  readingText,
  questions = [],
  options = [],
  media = [],
  selectedAnswer,
  selectedAnswers = {},
  isAnswered = false,
  correctAnswer,
  onAnswerSelect,
  isBookmarked = false,
  onBookmarkToggle,
  explanation,
  explanationMedia = [],
  mode = 'solving',
  answersHistory = {},
  currentQuestionId,
  className = '',
  imageUrl,
  category,
  formatText = text => text // Function for text formatting, default is identity
}) => {
  // Effect to restore previous answers in review mode
  useEffect(() => {
    if (mode === 'review' && 
        answersHistory[currentQuestionId] && 
        onAnswerSelect && 
        !isAnswered) {
      if (type === QUESTION_TYPES.SINGLE) {
        const previousAnswer = answersHistory[currentQuestionId].selectedAnswers[0];
        if (previousAnswer !== undefined) {
          onAnswerSelect(previousAnswer);
        }
      } else {
        const previousState = answersHistory[currentQuestionId];
        Object.entries(previousState.selectedAnswers).forEach(([index, answerId]) => {
          onAnswerSelect(parseInt(index), answerId);
        });
      }
    }
  }, [currentQuestionId, mode, answersHistory, isAnswered, onAnswerSelect, type]);

  const handleSingleAnswerSelect = (answerId) => {
    if (mode !== 'review' && !isAnswered) {
      onAnswerSelect(answerId);
    }
  };

  const handleMultipleAnswerSelect = (questionIndex, answerId) => {
    if (mode !== 'review' && !isAnswered) {
      onAnswerSelect(questionIndex, answerId);
    }
  };

  // Render for single questions
  if (type === QUESTION_TYPES.SINGLE) {
    return (
      <div className={`qd-question-display ${className}`}>
        <div className="qd-question-header">
          <div className="qd-question-title">{title || category || "Question 1"}</div>
          
          {onBookmarkToggle && (
            <button 
              className="qd-bookmark-btn" 
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                console.log("QD: Bookmark button clicked");
                console.log("Current state:", isBookmarked);
                console.log("Current question ID:", currentQuestionId);
                
                // Call the parent handler with the current question ID
                if (onBookmarkToggle) {
                  console.log("Calling parent onBookmarkToggle");
                  onBookmarkToggle(currentQuestionId);
                } else {
                  console.error("onBookmarkToggle is not defined!");
                }
              }}
              aria-label={isBookmarked ? "Remove bookmark" : "Add bookmark"}
            >
              {isBookmarked ? '★' : '☆'}
            </button>
          )}
        </div>
        
        {instruction && (
          <div className="qd-general-instruction">
            {formatText(instruction)}
          </div>
        )}

        {script && (
          <div className="qd-question-text">
            {formatText(script)}
          </div>
        )}

        {questionText && (
          <div className="qd-question-text">
            {formatText(questionText)}
          </div>
        )}

        {imageUrl && (
          <div className="qd-image-container">
            <img src={imageUrl} alt="Question" className="qd-question-image" />
          </div>
        )}

        <div className="qd-answer-options">
          {options.map((option, index) => {
            // Handle both array of objects with id/text or simple array of strings
            const optionId = option.id || index;
            const optionText = option.text || option;
            
            return (
              <button
                key={optionId}
                className={`qd-answer-option ${
                  selectedAnswer === optionId ? 'selected' : ''
                } ${
                  isAnswered ? (
                    optionId === correctAnswer || optionText === correctAnswer ? 'correct' : 
                    selectedAnswer === optionId ? 'incorrect' : ''
                  ) : ''
                } ${mode === 'review' ? 'review-mode' : ''}`}
                onClick={() => handleSingleAnswerSelect(optionId)}
                disabled={isAnswered || mode === 'review'}
              >
                <span className="qd-answer-number">{optionId + 1}</span>
                <span className="qd-answer-text">{formatText(optionText)}</span>
              </button>
            );
          })}
        </div>

        {isAnswered && explanation && (
          <div className="qd-explanation-section">
            <h3 className="qd-explanation-title">Explanation</h3>
            <div className="qd-explanation-text">{formatText(explanation)}</div>
          </div>
        )}
      </div>
    );
  }

  // Render for multiple questions
  return (
    <div className={`qd-question-display qd-multiple ${className}`}>
      <div className="qd-container">
        <div className="qd-content">
          {/* Left reading section */}
          <div className="qd-reading-section">
            <div className="qd-question-header">
              <div className="qd-question-title">{title || category || "Reading Question"}</div>
              {onBookmarkToggle && (
                <button 
                  className="qd-bookmark-btn" 
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    onBookmarkToggle(currentQuestionId);
                  }}
                  aria-label={isBookmarked ? "Remove bookmark" : "Add bookmark"}
                >
                  {isBookmarked ? '★' : '☆'}
                </button>
              )}
            </div>
            
            {instruction && (
              <div className="qd-general-instruction">
                {formatText(instruction)}
              </div>
            )}
            
            <div className="qd-text-scrollable">
              <div className="qd-question-text">
                {formatText(readingText)}
              </div>
              {imageUrl && (
                <div className="qd-image-container">
                  <img src={imageUrl} alt="Question" className="qd-question-image" />
                </div>
              )}
            </div>
          </div>

          {/* Right questions section */}
          <div className="qd-questions-section">
            <div className="qd-questions-scroll">
              {questions.map((subQuestion, index) => {
                // Support both object with id/text/options structure and simpler format
                const questionId = subQuestion.id || index;
                const questionText = subQuestion.text || subQuestion.question || '';
                const questionOptions = subQuestion.options || subQuestion.choices || [];
                const questionCorrectAnswer = subQuestion.correctAnswer || subQuestion.correct_answer || '';
                const questionExplanation = subQuestion.explanation || '';
                
                return (
                  <div key={questionId} className="qd-question-block">
                    <div className="qd-question-number">{index + 1}</div>
                    <div className="qd-question-text">{formatText(questionText)}</div>

                    <div className="qd-answer-options">
                      {questionOptions.map((option, optIndex) => {
                        // Handle both object with id/text and simple string
                        const optionId = option.id || optIndex;
                        const optionText = option.text || option;
                        
                        return (
                          <button
                            key={optionId}
                            className={`qd-answer-option ${
                              selectedAnswers[questionId] === optionId ? 'selected' : ''
                            } ${
                              isAnswered ? (
                                optionId === questionCorrectAnswer || optionText === questionCorrectAnswer ? 'correct' : 
                                selectedAnswers[questionId] === optionId ? 'incorrect' : ''
                              ) : ''
                            }`}
                            onClick={() => {
                              console.log("Option clicked:", questionId, optionId);
                              handleMultipleAnswerSelect(index, optionId);
                            }}
                            disabled={isAnswered || mode === 'review'}
                          >
                            <span className="qd-answer-number">{optionId + 1}</span>
                            <span className="qd-answer-text">{formatText(optionText)}</span>
                          </button>
                        );
                      })}
                    </div>

                    {isAnswered && questionExplanation && (
                      <div className="qd-explanation-section">
                        <h3 className="qd-explanation-title">Explanation</h3>
                        <div className="qd-explanation-text">{formatText(questionExplanation)}</div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestionDisplay;