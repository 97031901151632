import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams, useNavigate,  } from 'react-router-dom';
import '../css/QuestionView.css';
import mixpanel from '../mixpanel';
import { AlertTriangle, Send, X,} from 'lucide-react';
import QuestionLayout, { QUESTION_MODES } from '../components/common/Question/QuestionLayout';
import QuestionDisplay, { QUESTION_TYPES } from '../components/common/Question/components/QuestionDisplay';
import SessionComplete from '../components/common/Question/components/SessionComplete';
import ResultsReview from '../components/common/Question/components/ResultsReview';
import '../components/common/Question/components/SessionComplete/SessionComplete.css';
import '../components/common/Question/components/ResultsReview/ResultsReview.css';
import BeatLoader from 'react-spinners/BeatLoader';
import { sendQuestionChat,  } from '../api/exams';

const GET_PERSONALIZED_EXAM_API_URL = "https://api.getmina.co/api/v1/panel/personalized-exam";
const SUBMIT_PERSONALIZED_EXAM_API_URL = "https://api.getmina.co/api/v1/panel/personalized-exam/submit";
const REPORT_ISSUE_API_URL = "https://api.getmina.co/api/v1/panel/report-issue";

function PersonalizedExam() {
  const navigate = useNavigate();
  // const location = useLocation();
  const { exam_id } = useParams();
  const examIdNumber = Number(exam_id);
  
  // State management
  const [content, setContent] = useState([]);
  const [currentScriptIndex, setCurrentScriptIndex] = useState(0);
  const [answeredQuestions, setAnsweredQuestions] = useState({});
  const [revealedSets, setRevealedSets] = useState(new Set());
  const [isAnswerRevealed, setIsAnswerRevealed] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [timerMinutes, setTimerMinutes] = useState(0);
  const [timerSeconds, setTimerSeconds] = useState(0);
  const [showReportModal, setShowReportModal] = useState(false);
  const [selectedIssue, setSelectedIssue] = useState('');
  const [otherIssueText, setOtherIssueText] = useState('');
  const [showExitModal, setShowExitModal] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [viewState, setViewState] = useState('question'); // 'question', 'complete', 'results'
  const [examResults, setExamResults] = useState({
    totalQuestions: 0,
    correctAnswers: 0,
    timeSpent: 0,
    data: null
  });
  
  // Refs
  const hasFetchedQuestions = useRef(false);
  
  // Structured data for new UI
  const [groupedQuestions, setGroupedQuestions] = useState([]);
  const [currentQuestionSet, setCurrentQuestionSet] = useState([]);

  // Format time for display
  const formatTime = useCallback(() => {
    return `${String(timerMinutes).padStart(2, '0')}:${String(timerSeconds).padStart(2, '0')}`;
  }, [timerMinutes, timerSeconds]);

  // Fetch questions from API
  const fetchQuestions = useCallback(async () => {
    try {
      setLoading(true);
      const jwt = localStorage.getItem('jwt');
      const response = await fetch(`${GET_PERSONALIZED_EXAM_API_URL}/${examIdNumber}`, {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${jwt}`
        },
      });

      const data = await response.json();
      console.log("API response:", data);

      if (data && data.responses && Array.isArray(data.responses)) {
        setContent(data.responses.map(question => ({ 
          ...question, 
          selectedAnswer: null, 
          isAnswered: false 
        })));
      } else {
        console.error("Unexpected data structure:", data);
        setError("Unexpected data structure received from the server");
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching questions:", error);
      setError("Error fetching questions");
      setLoading(false);
    }
  }, [examIdNumber]);

  // Group questions by script
  const groupQuestionsByScript = useCallback((questions) => {
    const groupedQuestions = {};
    questions.forEach(question => {
      if (!question.script || question.script === "0") {
        // For questions without a script or script "0", create a unique key
        groupedQuestions[`single_${question.question_id}`] = [question];
      } else if (!groupedQuestions[question.script]) {
        groupedQuestions[question.script] = [];
        groupedQuestions[question.script].push(question);
      } else {
        groupedQuestions[question.script].push(question);
      }
    });
    return Object.values(groupedQuestions);
  }, []);

  // Initialize questions
  useEffect(() => {
    if (!hasFetchedQuestions.current) {
      fetchQuestions();
      hasFetchedQuestions.current = true;
    }
  }, [fetchQuestions]);

  // Group questions after fetch
  useEffect(() => {
    if (content.length > 0) {
      const questionSets = groupQuestionsByScript(content);
      setGroupedQuestions(questionSets);
      setCurrentQuestionSet(questionSets[currentScriptIndex] || []);
    }
  }, [content, currentScriptIndex, groupQuestionsByScript]);

  // Timer effect
  useEffect(() => {
    const timerInterval = setInterval(() => {
      setTimerSeconds(prev => {
        if (prev === 59) {
          setTimerMinutes(min => min + 1);
          return 0;
        }
        return prev + 1;
      });
    }, 1000);

    return () => clearInterval(timerInterval);
  }, []);
  
  // Format text to handle newlines and spacing
  const formatText = useCallback((text) => {
    if (!text || text === '0') return '';

    // First, replace {{NEWLINE}} with actual line breaks
    const textWithLineBreaks = text.replace(/{{NEWLINE}}/g, '\n');

    return textWithLineBreaks;
  }, []);

  // Handle answer selection
  const handleAnswerSelection = (questionId, index) => {
    if (!revealedSets.has(currentScriptIndex)) {
      console.log("Answer selected:", questionId, index);
      setAnsweredQuestions(prev => ({
        ...prev,
        [questionId]: index
      }));
    }
  };

  // Check answers and reveal correct responses
  const handleRevealAnswer = () => {
    setIsAnswerRevealed(true);
    setRevealedSets(prev => new Set(prev).add(currentScriptIndex));
  };

  // Navigate to next question set
  const handleNext = () => {
    if (currentScriptIndex < groupedQuestions.length - 1) {
      setCurrentScriptIndex(currentScriptIndex + 1);
      setIsAnswerRevealed(revealedSets.has(currentScriptIndex + 1));
    } else {
      handleSubmit();
    }
  };

  // Go back to previous question set
  const handlePrevious = () => {
    if (currentScriptIndex > 0) {
      setCurrentScriptIndex(currentScriptIndex - 1);
      setIsAnswerRevealed(revealedSets.has(currentScriptIndex - 1));
    }
  };

  // Submit exam answers
  const handleSubmit = async () => {
    const allQuestions = content.flat();
    
    // Create the request body exactly as it was in the old working version
    const requestBody = {
      exam_id: exam_id, // Use the string exam_id directly from the URL parameter
      responses: allQuestions.map(question => {
        const answerIndex = answeredQuestions[question.question_id];
        return {
          question_id: question.question_id,
          selected_answer: answerIndex !== undefined ? question.choices[answerIndex] : null
        };
      }).filter(response => response.selected_answer !== null)
    };

    // Log exact payload for comparison
    console.log("Submitting exam data:", requestBody);
    console.log("Submitting exam data (stringified):", JSON.stringify(requestBody));
    
    try {
      const jwt = localStorage.getItem('jwt');
      console.log("Using JWT (first 20 chars):", jwt.substring(0, 20) + "...");
      console.log("Submitting to URL:", SUBMIT_PERSONALIZED_EXAM_API_URL);
      
      // Log the actual structure of a sample question
      if (allQuestions.length > 0) {
        console.log("Sample question structure:", {
          original: allQuestions[0],
          choices_type: typeof allQuestions[0].choices,
          is_array: Array.isArray(allQuestions[0].choices),
          sample_choice: allQuestions[0].choices[0],
          sample_choice_type: typeof allQuestions[0].choices[0]
        });
      }
      
      const response = await fetch(SUBMIT_PERSONALIZED_EXAM_API_URL, {
        method: "POST",
        headers: {
          'Authorization': `Bearer ${jwt}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(requestBody)
      });
      
      // Log full response for debugging
      console.log("Response status:", response.status);
      console.log("Response headers:", Object.fromEntries([...response.headers]));
      
      if (!response.ok) {
        let errorText = "";
        try {
          const errorData = await response.json();
          errorText = JSON.stringify(errorData);
          console.log("Submit error (JSON):", errorData);
        } catch (e) {
          errorText = await response.text();
          console.log("Submit error (text):", errorText);
        }
        throw new Error(`Failed to submit exam: ${response.status} - ${errorText}`);
      }

      mixpanel.track('Exam Completed', {
        exam_id: exam_id,
        exam_type: "personalized_exam"
      });
      
      // Calculate correct answers for the score
      try {
        const flatContent = content.flat(); // Flatten if grouped
        const correctAnswers = Object.keys(answeredQuestions).reduce((count, questionId) => {
          const question = flatContent.find(q => q.question_id.toString() === questionId);
          if (!question) return count;
          
          const answerIndex = answeredQuestions[questionId];
          const selectedAnswer = question.choices[answerIndex];
          const correctAnswer = question.correct_answer;
          
          return (selectedAnswer === correctAnswer) ? count + 1 : count;
        }, 0);
        
        const totalQuestions = Object.keys(answeredQuestions).length;
        const score = totalQuestions > 0 ? Math.round((correctAnswers / totalQuestions) * 100) : 0;
        
        console.log("Score calculation:", { correctAnswers, totalQuestions, score });
        
        // Prepare the exam results data with calculated score
        setExamResults({
          totalQuestions: totalQuestions,
          correctAnswers: correctAnswers,
          score: score,
          timeSpent: timerMinutes * 60 + timerSeconds,
          data: response
        });
      } catch (error) {
        console.error("Error calculating score:", error);
        // Fallback to default values if calculation fails
        setExamResults({
          totalQuestions: groupedQuestions.length,
          correctAnswers: 0,
          score: 0,
          timeSpent: timerMinutes * 60 + timerSeconds,
          data: response
        });
      }
      
      console.log("Exam submitted successfully");
      
      // Change view state to show the session complete screen
      setViewState('complete');
    } catch (error) {
      console.error("Failed to submit exam:", error.message);
      alert(`Failed to submit exam: ${error.message}`);
    }
  };

  // Handle exit button click
  const handleExit = (e) => {
    e?.preventDefault();
    setShowExitModal(true);
  };

  // Handle Ask Mina button click
  const handleAskMina = () => {
    setShowChat(true);
    // If first time opening chat, add welcome message
    if (messages.length === 0) {
      setMessages([
        { id: 1, sender: 'bot', text: 'How can I help you with this question?' }
      ]);
    }
  };

  // Handle closing the chat
  const handleCloseChat = () => {
    setShowChat(false);
  };

  // Handle sending message to chat
  const handleSendMessage = async (e) => {
    e?.preventDefault();
    if (!inputValue.trim() || isTyping) return;
    
    // Add user message
    const userInput = inputValue;
    const userMessage = { id: Date.now(), sender: 'user', text: userInput };
    setMessages(prevMessages => [...prevMessages, userMessage]);
    setInputValue('');
    setIsTyping(true);
    
    try {
      // Prepare question context for the API
      const questionContext = {
        question_id: currentQuestion?.question_id,
        question_text: currentQuestion?.question,
        options: currentQuestion?.choices,
        correct_answer: currentQuestion?.correct_answer,
        explanation: currentQuestion?.explanation
      };
      
      let responseData;
      
      try {
        // Try to use our sendQuestionChat API
        responseData = await sendQuestionChat(questionContext, userInput);
        console.log('API response:', responseData);
      } catch (apiError) {
        console.error('Error calling API, falling back to mock response:', apiError);
        
        // If the API call fails, provide a meaningful fallback response
        let mockContent = `I'll help explain this question. The question is asking about "${questionContext.question_text}".`;
        
        if (questionContext.options) {
          mockContent += `\n\nThe correct answer is "${questionContext.correct_answer}".`;
        }
        
        if (questionContext.explanation) {
          mockContent += `\n\n${questionContext.explanation}`;
        } else {
          mockContent += `\n\nThere is no additional explanation provided for this question. Would you like me to analyze it further?`;
        }
        
        responseData = {
          choices: [{
            message: {
              content: mockContent
            }
          }]
        };
      }
      
      // Extract the bot's response text - handle different response formats
      let botResponseText;
      
      // Check if the response contains a JSON part
      if (typeof responseData === 'string') {
        // If the response is a string, try to split out any JSON part
        const jsonStartIndex = responseData.indexOf('{');
        if (jsonStartIndex > 0) {
          // Extract the text part before the JSON
          botResponseText = responseData.substring(0, jsonStartIndex).trim();
        } else {
          botResponseText = responseData;
        }
      } else if (responseData.choices && responseData.choices[0] && responseData.choices[0].message) {
        // OpenAI-like response format
        botResponseText = responseData.choices[0].message.content;
      } else if (responseData.response) {
        // Our custom API response format
        botResponseText = responseData.response;
      } else if (responseData.text) {
        // Alternative response format
        botResponseText = responseData.text;
      } else if (responseData.history) {
        // If we have a history array from the backend
        const systemMessages = responseData.history.filter(msg => msg.role === 'system');
        // Get the last system message which should be the AI response
        if (systemMessages.length > 0) {
          botResponseText = systemMessages[systemMessages.length - 1].content;
        } else {
          botResponseText = "I'm sorry, I couldn't process your question properly.";
        }
      } else {
        // Fallback if the response format is unexpected
        botResponseText = "I'm sorry, but I couldn't process your question properly. Please try asking something else.";
      }
      
      // Add bot message with the AI response
      const botMessage = { 
        id: Date.now(), 
        sender: 'bot', 
        text: botResponseText
      };
      setMessages(prevMessages => [...prevMessages, botMessage]);
    } catch (error) {
      console.error('Error processing chat message:', error);
      // Fallback response in case of error
      const errorMessage = { 
        id: Date.now(), 
        sender: 'bot', 
        text: 'I apologize, but I encountered an error while processing your question. Please try again later.' 
      };
      setMessages(prevMessages => [...prevMessages, errorMessage]);
    } finally {
      setIsTyping(false);
    }
  };

  // Open report issue modal
  const handleReportIssue = () => {
    setShowReportModal(true);
  };

  // Submit issue report
  const handleSubmitIssue = async () => {
    try {
      const jwt = localStorage.getItem('jwt');
      const issueMessage = selectedIssue === "Other" ? otherIssueText : selectedIssue;
      
      // Get current question ID
      const currentQuestion = currentQuestionSet[0];
      
      const response = await fetch(REPORT_ISSUE_API_URL, {
        method: "POST",
        headers: {
          'Authorization': `Bearer ${jwt}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          question_id: currentQuestion?.question_id,
          issue_message: issueMessage,
        })
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to submit issue: ${response.status} ${errorText}`);
      }

      alert("Issue reported successfully!");
      setShowReportModal(false);
      setSelectedIssue('');
      setOtherIssueText('');
    } catch (error) {
      console.error("Failed to submit issue:", error.message);
    }
  };

  // Loading state
  if (loading) {
    return (
      <div className="loading-container">
        <BeatLoader color="#3E00EF" />
        <p>Loading personalized exam...</p>
      </div>
    );
  }

  // Error state
  if (error) {
    return <div className="error-message">{error}</div>;
  }

  // No questions state
  if (!currentQuestionSet || currentQuestionSet.length === 0) {
    return <div className="error-message">No questions available.</div>;
  }

  // Get current question
  const currentQuestion = currentQuestionSet[0];
  const hasScript = currentQuestion?.script && currentQuestion.script !== "0";
  // const remainingQuestions = groupedQuestions.length - currentScriptIndex;
  const allQuestionsInSetAnswered = currentQuestionSet.every(q => {
    const answered = answeredQuestions[q.question_id] !== undefined;
    console.log(`Question ${q.question_id} answered:`, answered);
    return answered;
  });
  // Use our state variable instead of calculating isRevealed each time
  // This ensures the state variable is used and prevents the ESLint warning
  
  // Prepare questions for the QuestionDisplay component
  let questionType = QUESTION_TYPES.SINGLE;
  let options = [];
  let questions = [];
  
  if (hasScript && currentQuestionSet.length > 1) {
    // Multiple questions with a reading passage
    questionType = QUESTION_TYPES.MULTIPLE;
    questions = currentQuestionSet.map((q, idx) => ({
      id: q.question_id,
      text: q.question,
      options: [
        ...q.choices.map((choice, index) => ({ id: index, text: choice }))
      ],
      correctAnswer: q.correct_answer,
      explanation: q.explanation
    }));
  } else {
    // Single question
    questionType = QUESTION_TYPES.SINGLE;
    options = [
      ...currentQuestion.choices.map((choice, index) => ({ id: index, text: choice }))
    ];
  }

  // Handle viewing results after completion screen
  const handleViewResults = () => {
    setViewState('results');
  };

  // Handle back to dashboard
  const handleBackToDashboard = () => {
    // Navigate back to dashboard
    navigate('/');
  };

  // Render based on view state
  if (viewState === 'complete') {
    return (
      <SessionComplete 
        score={Math.round((examResults.correctAnswers / examResults.totalQuestions) * 100) || 0}
        timeInSeconds={examResults.timeSpent}
        onViewResults={handleViewResults}
        buttonText="View results"
      />
    );
  }
  
  if (viewState === 'results') {
    // Format questions for ResultsReview component
    const flatContent = content.flat(); // Ensure content is flattened
    console.log("Preparing results with answeredQuestions:", answeredQuestions);
    
    const reviewQuestions = flatContent.map(question => {
      const answerIndex = answeredQuestions[question.question_id];
      console.log(`Question ${question.question_id} answer index:`, answerIndex);
      
      // If no answer was selected
      if (answerIndex === undefined) {
        return {
          id: question.question_id,
          text: question.question,
          isCorrect: false,
          isBookmarked: false
        };
      }
      
      // Get the selected answer from the choices array using the index
      const selectedAnswer = question.choices[answerIndex];
      console.log(`Question ${question.question_id} selected:`, selectedAnswer, "correct:", question.correct_answer);
      
      // Compare with correct answer
      const isCorrect = selectedAnswer === question.correct_answer;
      
      return {
        id: question.question_id,
        text: question.question,
        isCorrect: isCorrect,
        isBookmarked: false
      };
    });
    
    return (
      <ResultsReview 
        questions={reviewQuestions}
        performanceData={{
          timeSpent: examResults.timeSpent
        }}
        onQuestionClick={(questionId) => {
          // For now, just redirect to review page
          navigate(`/review-personalized/${exam_id}`);
        }}
        onBackToDashboard={handleBackToDashboard}
      />
    );
  }

  // Question solving view
  return (
    <>
      <QuestionLayout
        timer={formatTime()}
        totalQuestions={groupedQuestions.length}
        currentQuestion={currentScriptIndex + 1}
        onExit={handleExit}
        isAnswered={isAnswerRevealed}
        mode={QUESTION_MODES.SOLVING}
        footer={
          <>
            <button 
              className="report-btn" 
              onClick={handleReportIssue}
            >
              <AlertTriangle size={20} />
              <span className="report-text">Report Issue</span>
            </button>
            
            <button 
              className="ask-mina-btn" 
              onClick={handleAskMina}
              style={{ display: isAnswerRevealed ? 'flex' : 'none' }}
            >
              Ask Mina
            </button>
            
            <div className="navigation-buttons">
              <button 
                className="nav-btn previous" 
                disabled={currentScriptIndex === 0}
                onClick={handlePrevious}
              >
                Previous
              </button>
              
              <button 
                className="nav-btn next" 
                onClick={isAnswerRevealed ? handleNext : handleRevealAnswer}
                disabled={!allQuestionsInSetAnswered}
              >
                {isAnswerRevealed ? 
                  (currentScriptIndex === groupedQuestions.length - 1 ? 'Finish' : 'Next') : 
                  'Check Answer'}
              </button>
            </div>
          </>
        }
      >
        <QuestionDisplay
          type={questionType}
          title={`Question ${currentScriptIndex + 1}`}
          instruction={currentQuestion.instruction}
          script={!hasScript ? currentQuestion.question : null}
          questionText={!hasScript ? null : null}
          readingText={hasScript ? currentQuestion.script : null}
          questions={questions}
          options={options}
          selectedAnswer={answeredQuestions[currentQuestion.question_id]}
          selectedAnswers={answeredQuestions}
          isAnswered={isAnswerRevealed}
          correctAnswer={currentQuestion.correct_answer}
          onAnswerSelect={hasScript && currentQuestionSet.length > 1 
            ? (questionIndex, answerId) => {
                console.log("Multiple question answer:", questionIndex, answerId);
                if (questions[questionIndex]) {
                  const questionId = questions[questionIndex].id;
                  console.log("Found question ID:", questionId);
                  handleAnswerSelection(questionId, answerId);
                } else {
                  console.error("No question found at index:", questionIndex);
                }
              }
            : (answerId) => handleAnswerSelection(currentQuestion.question_id, answerId)
          }
          explanation={isAnswerRevealed ? currentQuestion.explanation : null}
          formatText={formatText}
          imageUrl={currentQuestion?.script_image?.urls?.[0]}
        />
      </QuestionLayout>

      {/* Exit Modal */}
      {showExitModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3>Are you sure you want to exit?</h3>
            <p>Your progress won't be saved.</p>
            <div className="modal-buttons">
              <button 
                className="modal-button secondary" 
                onClick={() => setShowExitModal(false)}
              >
                No
              </button>
              <button 
                className="modal-button primary" 
                onClick={() => {
                  mixpanel.track('Cancel Personalized Exam', {
                    exam_id: exam_id,
                    question_id: currentQuestion?.question_id,
                    question_number: currentScriptIndex + 1,
                  });
                  navigate('/');
                }}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Report Issue Modal */}
      {showReportModal && (
        <div className="modal-overlay">
          <div className="report-modal">
            <h3>
              Report Issue
              <button onClick={() => setShowReportModal(false)}>×</button>
            </h3>
            <h4>What's wrong?</h4>
            <div className="report-options">
              {[
                { value: 'None of the options is correct', label: 'None of the options is correct' },
                { value: 'Question has incorrect or missing data', label: 'Question has incorrect or missing data' },
                { value: 'Questions/Options/Infographics are not visible properly or missing', label: 'Questions/Options/Infographics are not visible properly or missing' },
                { value: 'Question or option has a typo', label: 'Question or option has a typo' },
                { value: 'The question is confusing or unclear', label: 'The question is confusing or unclear' },
                { value: 'Other', label: 'Other' }
              ].map(option => (
                <label key={option.value} className="report-option">
                  <input 
                    type="radio" 
                    name="issue" 
                    value={option.value}
                    checked={selectedIssue === option.value}
                    onChange={(e) => setSelectedIssue(e.target.value)}
                  />
                  {option.label}
                </label>
              ))}
            </div>
            
            {selectedIssue === 'Other' && (
              <div className="other-description">
                <label>Description of issue:</label>
                <textarea
                  value={otherIssueText}
                  onChange={(e) => setOtherIssueText(e.target.value)}
                  placeholder="Please describe the issue..."
                  rows={4}
                />
              </div>
            )}
            
            <button 
              className="submit-issue" 
              onClick={handleSubmitIssue}
              disabled={selectedIssue === 'Other' && !otherIssueText.trim()}
            >
              Submit Issue
            </button>
          </div>
        </div>
      )}

      {/* Context Chat Component */}
      <div className={`context-chat ${showChat ? 'visible' : ''}`}>
        <div className="context-chat-header">
          <h3>Chat with Mina</h3>
          <button 
            className="context-chat-close" 
            onClick={handleCloseChat}
            aria-label="Close chat"
          >
            <X size={20} />
          </button>
        </div>

        <div className="context-chat-messages">
          {messages.map((message) => (
            <div key={message.id} className={`context-chat-message ${message.sender}`}>
              {message.sender === 'bot' && (
                <div className="bot-avatar">
                  <img src="/mina.png" alt="Mina" className="avatar-logo" />
                </div>
              )}
              <div className="message-content">{message.text}</div>
            </div>
          ))}
          
          {isTyping && (
            <div className="context-chat-message bot">
              <div className="bot-avatar">
                <img src="/mina.png" alt="Mina" className="avatar-logo" />
              </div>
              <div className="message-content">
                <span className="typing-indicator">
                  <span className="dot"></span>
                  <span className="dot"></span>
                  <span className="dot"></span>
                </span>
              </div>
            </div>
          )}
        </div>

        <div className="context-chat-input-container">
          <form onSubmit={handleSendMessage}>
            <input
              type="text"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              placeholder="Type your message..."
              disabled={isTyping}
            />
            <button 
              type="submit" 
              className="send-message" 
              disabled={!inputValue.trim() || isTyping}
              aria-label="Send message"
            >
              <Send size={20} />
            </button>
          </form>
        </div>
      </div>
      
      {/* Chat Overlay */}
      <div 
        className={`context-chat-overlay ${showChat ? 'visible' : ''}`}
        onClick={handleCloseChat}
      />
    </>
  );
}

export default PersonalizedExam;