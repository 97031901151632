// ChatView.js
// Modern chat interface component for Mina
import React, { useState, useRef, useEffect } from 'react';
import { ArrowUp, Menu, X as CloseIcon, RefreshCw } from 'lucide-react';
import '../css/ChatView.css';

// Message status constants
const MESSAGE_STATUS = {
  SENDING: 'sending',
  SENT: 'sent',
  DELIVERED: 'delivered',
  READ: 'read',
  FAILED: 'failed'
};

// Chat view states
const CHAT_VIEW_STATES = {
  LOADING: 'loading',
  EMPTY: 'empty',
  ACTIVE: 'active',
  ERROR: 'error'
};

const ChatView = ({ onClose }) => {
  // State for messages and UI
  const [messages, setMessages] = useState([]);
  const [history] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const [viewState, setViewState] = useState(CHAT_VIEW_STATES.EMPTY);
  const [error, setError] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [showHistory, setShowHistory] = useState(false);
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);
  const [responseHistory, setResponseHistory] = useState([]);

  // Refs
  const chatMainRef = useRef(null);
  const messagesEndRef = useRef(null);
  const inputRef = useRef(null);

  // Auto-scroll effect
  useEffect(() => {
    const scrollToBottom = () => {
      if (chatMainRef.current) {
        chatMainRef.current.scrollTop = chatMainRef.current.scrollHeight;
      }
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    scrollToBottom();

    const handleResize = () => {
      scrollToBottom();
      if (document.activeElement?.tagName === 'INPUT') {
        setTimeout(scrollToBottom, 100);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [messages]);

  // Toggle chat history sidebar
  const toggleHistory = () => {
    setShowHistory(!showHistory);
    if (!showHistory) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  };

  // Close chat history sidebar
  const closeHistory = () => {
    setShowHistory(false);
    document.body.style.overflow = 'unset';
  };

  // Handle input focus (for mobile keyboard)
  const handleInputFocus = () => {
    setIsKeyboardOpen(true);
    // Only scroll to bottom if we have messages
    if (messages.length > 0) {
      setTimeout(() => {
        chatMainRef.current?.scrollTo({
          top: chatMainRef.current.scrollHeight,
          behavior: 'smooth'
        });
      }, 100);
    }
  };

  // Handle input blur
  const handleInputBlur = () => {
    setIsKeyboardOpen(false);
  };

  // Handle sending a message
  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (!inputValue.trim() || isTyping) return;

    const text = inputValue;
    setInputValue('');
    
    // Add user message to chat
    const newUserMessage = { role: 'user', content: text };
    setMessages(prev => [...prev, newUserMessage]);
    
    // Set state to show we're expecting a response
    setIsTyping(true);
    setViewState(CHAT_VIEW_STATES.ACTIVE);

    try {
      const requestBody = {
        user_input: text,
        history: responseHistory,
      };

      // Track in mixpanel if it's available
      if (window.mixpanel) {
        window.mixpanel.track('Ask Mina', {
          user_input: text,
        });
      }

      const url = responseHistory.length === 0 ? 
        "https://api.getmina.co/api/v1/panel/ask-mina-help" :
        "https://api.getmina.co/api/v1/panel/test-convo";

      const jwt = localStorage.getItem('jwt');
      const response = await fetch(url, {
        method: "POST",
        headers: { 
          'Authorization': `Bearer ${jwt}`, 
          "Content-Type": "application/json" 
        },
        body: JSON.stringify(requestBody)
      });

      const reader = response.body.getReader();
      let currentDisplayContent = '';
      let fullResponse = '';

      function processText({ done, value }) {
        if (value) {
          const chunk = new TextDecoder("utf-8").decode(value, { stream: !done });
          fullResponse += chunk;

          const jsonStartIndex = chunk.indexOf('{"history":');
          if (jsonStartIndex !== -1) {
            currentDisplayContent += chunk.substring(0, jsonStartIndex);
          } else {
            currentDisplayContent += chunk;
          }

          setMessages(prev => {
            const newHistory = [...prev];
            if (newHistory.length > 0 && newHistory[newHistory.length - 1].role === 'bot') {
              newHistory[newHistory.length - 1].content = currentDisplayContent;
            } else {
              newHistory.push({ role: 'bot', content: currentDisplayContent });
            }
            return newHistory;
          });
        }

        if (!done) {
          return reader.read().then(processText);
        } else {
          const historyMatch = fullResponse.match(/{"history":\s*\[.*\]}/);
          if (historyMatch) {
            const history = JSON.parse(historyMatch[0]).history;
            setResponseHistory(history);
          }

          const historyIndex = currentDisplayContent.indexOf('{"history":');
          if (historyIndex !== -1) {
            currentDisplayContent = currentDisplayContent.substring(0, historyIndex);
          }

          setMessages(prev => {
            const newHistory = [...prev];
            if (newHistory.length > 0 && newHistory[newHistory.length - 1].role === 'bot') {
              newHistory[newHistory.length - 1].content = currentDisplayContent;
            } else {
              newHistory.push({ role: 'bot', content: currentDisplayContent });
            }
            return newHistory;
          });

          setIsTyping(false);
        }
      }

      reader.read().then(processText);
    } catch (error) {
      console.error("Error fetching Mina's response:", error);
      setIsTyping(false);
      setError("There was a problem connecting to Mina. Please try again.");
      setViewState(CHAT_VIEW_STATES.ERROR);
    }
  };

  // For now, empty functions for history features that will be implemented later
  const handleChatSelect = (chatId) => {
    // To be implemented with backend
    closeHistory();
  };

  const handleNewChat = () => {
    // Clear messages to start fresh
    setMessages([]);
    setResponseHistory([]);
    setViewState(CHAT_VIEW_STATES.EMPTY);
    closeHistory();
  };

  // Render loading state
  const renderLoading = () => (
    <div className="chat-loading">
      <div className="loading-spinner"></div>
      <p>Loading chat...</p>
    </div>
  );

  // Render error state
  const renderError = () => (
    <div className="chat-error">
      <p>{error || "Something went wrong"}</p>
      <button onClick={() => window.location.reload()}>Retry</button>
    </div>
  );

  // Render message status indicator
  const renderMessageStatus = (status) => {
    switch(status) {
      case MESSAGE_STATUS.SENDING:
        return <span className="message-status sending">Sending...</span>;
      case MESSAGE_STATUS.FAILED:
        return (
          <span className="message-status failed">
            Failed
            <button 
              className="retry-btn"
              aria-label="Retry sending message"
            >
              <RefreshCw size={12} />
            </button>
          </span>
        );
      default:
        return null;
    }
  };

  return (
    <div className={`chat-view ${isKeyboardOpen ? 'keyboard-open' : ''}`}>
      {/* History Toggle */}
      <button 
        className="history-toggle"
        onClick={toggleHistory}
        aria-label="Toggle chat history"
      >
        <Menu size={20} />
      </button>

      {/* History Panel */}
      <div className={`history-panel ${showHistory ? 'visible' : ''}`}>
        <div className="history-header">
          <h3>Chat History</h3>
          <button className="history-close" onClick={closeHistory}>
            <CloseIcon size={20} />
          </button>
        </div>
        
        <button 
          className="new-chat-btn"
          onClick={handleNewChat}
        >
          + New Chat
        </button>
        
        <div className="history-items">
          {history.length === 0 ? (
            <div className="history-empty">
              <p>No chat history yet</p>
            </div>
          ) : (
            history.map(chat => (
              <div 
                key={chat.id} 
                className="history-item"
                onClick={() => handleChatSelect(chat.id)}
              >
                <h4>{chat.title}</h4>
                <p>{chat.preview}</p>
                <span className="history-time">
                  {new Date(chat.timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                </span>
                {chat.unread && <span className="unread-indicator" />}
              </div>
            ))
          )}
        </div>
      </div>

      {/* Main Chat Area */}
      <main className="chat-main" ref={chatMainRef}>
        {viewState === CHAT_VIEW_STATES.LOADING && renderLoading()}
        {viewState === CHAT_VIEW_STATES.ERROR && renderError()}
        
        {viewState === CHAT_VIEW_STATES.EMPTY && (
          <div className="welcome-screen">
            <div className="welcome-icon"><img src="/mina.png" alt="Mina" className="welcome-logo" /></div>
            <h1>Mina</h1>
            <p>How can I help you today?</p>
          </div>
        )}
        
        {(viewState === CHAT_VIEW_STATES.ACTIVE || messages.length > 0) && (
          <div className="messages-container">
            {messages.map((msg, index) => (
              <div key={index} className={`message ${msg.role}-message`}>
                {msg.role === 'bot' && <div className="bot-avatar"><img src="/mina.png" alt="Mina" className="avatar-logo" /></div>}
                <div className="message-bubble">
                  {msg.content}
                  {msg.status && renderMessageStatus(msg.status)}
                </div>
              </div>
            ))}
            {isTyping && (
              <div className="message bot-message">
                <div className="bot-avatar"><img src="/mina.png" alt="Mina" className="avatar-logo" /></div>
                <div className="message-bubble">
                  <span className="typing-indicator">
                    <span className="dot"></span>
                    <span className="dot"></span>
                    <span className="dot"></span>
                  </span>
                </div>
              </div>
            )}
            <div ref={messagesEndRef} />
          </div>
        )}
      </main>

      {/* Input Area */}
      <div className="chat-input-container">
        <form 
          onSubmit={handleSendMessage} 
          className="chat-input-form"
        >
          <div className="input-wrapper">
            <input
              ref={inputRef}
              type="text"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
              placeholder="Message Mina..."
              disabled={isTyping || viewState === CHAT_VIEW_STATES.ERROR}
            />
            <div className="input-actions">
              <button 
                type="submit" 
                className="send-btn" 
                disabled={!inputValue.trim() || isTyping || viewState === CHAT_VIEW_STATES.ERROR}
                aria-label="Send message"
              >
                <ArrowUp size={20} />
              </button>
            </div>
          </div>
        </form>
      </div>

      {/* History Overlay */}
      <div 
        className={`history-overlay ${showHistory ? 'visible' : ''}`} 
        onClick={closeHistory}
      />
    </div>
  );
};

export default ChatView;