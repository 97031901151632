import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../css/Dashboard.css';

// This component serves as a hidden admin portal
const AdminPortal = () => {
  const [diagnosticExams, setDiagnosticExams] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Prevent any navigation away from this page
    const handleBeforeUnload = (e) => {
      // Store that user accessed admin portal
      sessionStorage.setItem('adminAccess', 'true');
    };
    
    window.addEventListener('beforeunload', handleBeforeUnload);
    
    // Fetch diagnostic exams on load
    const fetchDiagnosticExams = async () => {
      try {
        const jwt = localStorage.getItem('jwt');
        const response = await fetch('https://api.getmina.co/api/v1/panel/teacher/diagnostic-exams', {
          method: 'GET',
          headers: { 'Authorization': `Bearer ${jwt}` }
        });
        
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        
        const data = await response.json();
        setDiagnosticExams(data);
      } catch (error) {
        console.error('Error fetching diagnostic exams:', error);
        setError('Failed to load admin panel data. Please check your connection and try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchDiagnosticExams();
    
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  if (loading) {
    return (
      <div className="admin-portal loading">
        <h1>Loading Admin Panel...</h1>
        <div className="loader"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="admin-portal error">
        <h1>Admin Portal</h1>
        <div className="error-message">{error}</div>
        <button onClick={() => window.location.reload()}>Try Again</button>
      </div>
    );
  }

  return (
    <div className="admin-portal">
      <div className="admin-header">
        <h1>Admin Portal</h1>
        <p>This panel gives you access to teacher/admin functions</p>
      </div>
      
      <div className="admin-section">
        <h2>Diagnostic Exams Management</h2>
        <div className="admin-card">
          <div className="admin-card-content">
            <h3>View All Diagnostic Exams</h3>
            <p>See all diagnostic exams and the users who took them</p>
            <div className="exam-list">
              {diagnosticExams.length > 0 ? (
                <ul>
                  {diagnosticExams.map((exam) => (
                    <li key={exam.exam_id} className="exam-item">
                      <Link to={`/exam/${exam.exam_id}/users`} className="exam-link">
                        <span className="exam-level">Level: {exam.level}</span>
                        <span className="exam-date">Created: {new Date(exam.created_at).toLocaleDateString()}</span>
                      </Link>
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No diagnostic exams found.</p>
              )}
            </div>
          </div>
        </div>
      </div>
      
      <div className="admin-section">
        <h2>Direct Access Links</h2>
        <div className="admin-links">
          <div className="admin-link-card" onClick={() => navigate('/DiagnosticExams')}>
            <h3>Diagnostic Exams</h3>
            <p>View all diagnostic exams in the system</p>
          </div>
          
          {diagnosticExams.length > 0 && (
            <div className="admin-link-card" onClick={() => navigate(`/exam/${diagnosticExams[0].exam_id}/users`)}>
              <h3>Latest Exam Users</h3>
              <p>View users for the most recent diagnostic exam</p>
            </div>
          )}
          
          <div className="admin-link-card" onClick={() => window.open('/DiagnosticExams', '_blank')}>
            <h3>Open Admin Panel in New Tab</h3>
            <p>Try opening the admin panel in a new tab</p>
          </div>
        </div>
      </div>
      
      <div className="admin-tips">
        <h3>Troubleshooting Tips</h3>
        <ul>
          <li>If direct links don't work, try clicking on a specific exam above</li>
          <li>If you're redirected away, try using incognito mode or clearing cookies</li>
          <li>Make sure you have the proper admin permissions</li>
        </ul>
      </div>
    </div>
  );
};

export default AdminPortal;