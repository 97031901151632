import React, { useState, useEffect } from 'react';
import { 
  // Lock, 
  // Bell, 
  // Calendar, 
  // Trophy, 
  Target, 
  // User, 
  // AlertTriangle, 
  LogOut 
} from 'lucide-react';
import '../css/Settings.css';

const SettingsView = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [ setNotifications] = useState(true);
  // const [ setIsEditing] = useState(false);
  const [profileData, setProfileData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    target_level: ''
  });
  const [jlptData, setJlptData] = useState({
    targetLevel: 'N5',
    examSession: 'july'
  });
  const [clickCount, setClickCount] = useState(0);

  // Fetch user data on component mount
  useEffect(() => {
    const fetchUserData = async () => {
      const jwt = localStorage.getItem('jwt');
      
      try {
        const response = await fetch('https://api.getmina.co/api/v1/auth/me', {
          headers: { 'Authorization': `Bearer ${jwt}` },
          method: 'GET'
        });
        
        if (response.ok) {
          const userData = await response.json();
          setUser(userData);
          
          // Split name into first and last name if available
          let firstName = '';
          let lastName = '';
          if (userData.name) {
            const nameParts = userData.name.split(' ');
            firstName = nameParts[0] || '';
            lastName = nameParts.slice(1).join(' ') || '';
          }
          
          // The backend returns the level as 'level', not 'target_level'
          const userLevel = userData.level || userData.target_level || 'N5';
          console.log('User level from API:', userLevel);
          
          setProfileData({
            firstName: firstName,
            lastName: lastName,
            email: userData.email || '',
            target_level: userLevel
          });
          
          setJlptData({
            targetLevel: userLevel,
            examSession: 'july' // Default to July as this is not in current API
          });
          
          setNotifications(userData.notification_settings?.email !== false);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setLoading(false);
      }
    };
    
    fetchUserData();
  }, [setNotifications]);

  // const handleProfileChange = (e) => {
  //   const { name, value } = e.target;
  //   setProfileData(prev => ({
  //     ...prev,
  //     [name]: value
  //   }));
  // };

  // const handleJlptChange = (e) => {
  //   const { name, value } = e.target;
    
  //   // Prevent changes to targetLevel - as it's now locked
  //   if (name === 'targetLevel') {
  //     console.log('Target level change prevented - field is locked');
  //     return;
  //   }
    
  //   setJlptData(prev => ({
  //     ...prev,
  //     [name]: value
  //   }));
    
  //   // Only handle changes for examSession now
  //   if (name === 'examSession') {
  //     // No need to update backend for exam session changes currently
  //     // If needed in the future, add the API call here
  //   }
  // };

  // const toggleNotifications = () => {
  //   const newValue = !notifications;
  //   setNotifications(newValue);
    
  //   // Update only notifications settings on server, not the target level
  //   saveProfileChanges({
  //     name: `${profileData.firstName} ${profileData.lastName}`,
  //     notification_settings: {
  //       email: newValue,
  //       app: newValue
  //     }
  //   });
  // };

  // const saveProfile = () => {
  //   // Build full name from first and last name
  //   const fullName = `${profileData.firstName} ${profileData.lastName}`.trim();
    
  //   // Save to backend - don't include target_level as it can't be changed
  //   saveProfileChanges({
  //     name: fullName,
  //     notification_settings: {
  //       email: notifications,
  //       app: notifications
  //     }
  //   });
    
  //   setIsEditing(false);
  // };

  // const saveProfileChanges = async (data) => {
  //   const jwt = localStorage.getItem('jwt');
    
  //   try {
  //     console.log('Updating profile with data:', data);
      
  //     // Make sure we have the required fields
  //     const updatedData = {
  //       ...data
  //     };
      
  //     // Ensure name exists
  //     if (!updatedData.name || updatedData.name.trim() === '') {
  //       updatedData.name = profileData.email || 'User';
  //     }
      
  //     // Try to update profile with better error handling
  //     try {
  //       // Use the correct backend endpoint for changing level
  //       const response = await fetch('https://api.getmina.co/api/v1/auth/change-level', {
  //         method: 'POST',
  //         headers: {
  //           'Authorization': `Bearer ${jwt}`,
  //           'Content-Type': 'application/json'
  //         },
  //         body: JSON.stringify({ level: updatedData.target_level })
  //       });
        
  //       if (response.ok) {
  //         // Update user state with new data
  //         setUser(prev => ({
  //           ...prev,
  //           ...updatedData
  //         }));
          
  //         // Also update targetLevel in localStorage to ensure diagnostic exams use the right level
  //         if (updatedData.target_level) {
  //           localStorage.setItem('selectedLevel', updatedData.target_level);
  //         }
          
  //         console.log('Profile updated successfully');
  //       } else {
  //         let errorMessage = `Status: ${response.status} ${response.statusText}`;
  //         try {
  //           const errorData = await response.json();
  //           console.error('Failed to update profile:', errorData);
  //           errorMessage += ` - ${errorData.message || 'Unknown error'}`;
  //         } catch (parseError) {
  //           console.error('Error parsing error response:', parseError);
  //         }
          
  //         alert(`Failed to update profile. ${errorMessage}`);
  //       }
  //     } catch (fetchError) {
  //       console.error('Network error updating profile:', fetchError);
  //       alert('Error updating profile. Please check your connection and try again.');
  //     }
  //   } catch (error) {
  //     console.error('Error in profile update process:', error);
  //     alert('Error updating profile. Please check your connection and try again.');
  //   }
  // };

  const handleLogout = () => {
    localStorage.removeItem('jwt');
    window.location.href = '/login';
  };

  // const confirmDeleteAccount = () => {
  //   if (window.confirm("Are you sure you want to delete your account? This action cannot be undone.")) {
  //     // Handle account deletion - in a real implementation, this would call an API
  //     alert("Account deletion would be processed in a real implementation");
  //   }
  // };

  if (loading) {
    return (
      <div className="settings-loading">
        <div className="loading-spinner"></div>
        <p>Loading your settings...</p>
      </div>
    );
  }

  // Stats variables removed

  return (
    <div className="settings">
      <div className="settings-header">
        <div>
          <h1>Settings</h1>
        </div>
      </div>

      <div className="settings-section">
        <h2>Account</h2>
        <div className="settings-card">
          <div className="account-info">
            <div className="profile-icon">
              {profileData.firstName ? profileData.firstName.charAt(0).toUpperCase() : '?'}
            </div>
            <div className="account-details">
              <h3>{`${profileData.firstName} ${profileData.lastName}`.trim() || 'User'}</h3>
              <p>{profileData.email || 'email@example.com'}</p>
            </div>
          </div>
        </div>

        <div className="subscription-card">
          <div className="subscription-header">
            <h3>Subscription</h3>
            <span className={`subscription-badge ${user?.status === 'active' ? 'premium' : 'free'}`}>
              {user?.status === 'active' ? 'Premium' : 'Free'}
            </span>
          </div>
          <p>
            {user?.status === 'active' 
              ? 'You have full access to all premium features.' 
              : 'Upgrade to premium for full access to all features.'}
          </p>
          {user?.status !== 'active' && (
            <button 
              className="upgrade-btn"
              onClick={() => window.location.href = 'https://buy.stripe.com/5kA154aaHaVcbGU145'}
            >
              <span className="icon">⭐</span>
              Upgrade Now
            </button>
          )}
        </div>
      </div>

      {/* Statistics section removed */}

      <div className="settings-section">
        <h2>JLPT Settings</h2>
        <div className="jlpt-settings">
          <div className="settings-option">
            <div className="option-info">
              <div className="option-icon">
                <Target size={20} />
              </div>
              <div className="option-text">
                <h3>Target Level</h3>
                <p>Which JLPT level are you aiming for?</p>
              </div>
            </div>
            <div className="select-wrapper no-arrow">
              <div className="static-level">
                {jlptData.targetLevel}
              </div>
            </div>
          </div>
        </div>
      </div>



      <div className="settings-section">
        <h2>Account Actions</h2>
        <div className="settings-option">
          <div className="option-info">
            <div className="option-icon danger">
              <LogOut size={20} />
            </div>
            <div className="option-text">
              <h3>Log Out</h3>
              <p>Sign out of your account</p>
            </div>
          </div>
          <button 
            className="delete-account-btn" 
            onClick={handleLogout}
          >
            Log Out
          </button>
        </div>
      </div>
      
      <div className="app-info">
        <div className="app-logo">
          <img 
            src="/mina.png" 
            alt="Mina" 
            className="logo" 
            onClick={() => {
              // Increment the click counter
              const newCount = clickCount + 1;
              setClickCount(newCount);
              
              // After 5 clicks, redirect to admin portal
              if (newCount >= 5) {
                window.location.href = '/account-info';
              }
            }}
            style={{ cursor: 'pointer' }}
          />
          <span>Mina</span>
        </div>
        <div className="app-version" 
             onClick={() => window.location.href = '/user-profile'}
             style={{ cursor: 'default' }}>
          Version 2.5.0
        </div>
      </div>
    </div>
  );
};

export default SettingsView;