// Dashboard.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Timer as TimerIcon, Check as CheckIcon,  } from 'lucide-react';
import '../css/Dashboard.css';
// Stats component removed

const DashboardView = () => {
  const [user, setUser] = useState({
    name: '',
    email: '',
    isPremium: false
  });
  // const [referralStatus, setReferralStatus] = useState({
  //   count: 0,
  //   completed: false
  // });
  const [showShareToast] = useState(false);
  
  useEffect(() => {
    // Fetch user data
    const fetchData = async () => {
      try {
        const jwt = localStorage.getItem('jwt');
        
        // Fetch user data
        const userResponse = await fetch("https://api.getmina.co/api/v1/auth/me", {
          headers: { 'Authorization': `Bearer ${jwt}` },
          method: "GET",
        });
        
        if (userResponse.ok) {
          const userData = await userResponse.json();
          console.log('User data from API:', userData);
          const isPremium = userData.status === 'active';
          console.log('Is user premium?', isPremium, 'Status value:', userData.status);
          
          setUser(prev => ({
            ...prev,
            name: userData.name || userData.email.split('@')[0],
            email: userData.email,
            isPremium: isPremium
          }));
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);
  
  // Handle share button click
  // const handleShare = async () => {
  //   const referralLink = "https://getmina.co/r/invite";
    
  //   try {
  //     // Check if Web Share API is supported AND available on this device
  //     if (navigator.share && typeof navigator.share === 'function') {
  //       try {
  //         // For iOS/mobile devices, use the native share menu
  //         await navigator.share({
  //           title: 'Join me on Mina!',
  //           text: 'Learn Japanese with me! Use my link to sign up:',
  //           url: referralLink
  //         });
  //         console.log('Shared successfully using Web Share API');
  //       } catch (shareError) {
  //         console.error('Error with Web Share API:', shareError);
          
  //         // Fallback for iOS in case share API fails
  //         fallbackShare(referralLink);
  //       }
  //     } else {
  //       // Fallback for desktop or browsers without share API
  //       fallbackShare(referralLink);
  //     }
  //   } catch (error) {
  //     console.error('General error in sharing:', error);
  //     // Final fallback - show an alert with the link
  //     alert(`Copy this link to share: ${referralLink}`);
  //   }
  // };
  
  // Fallback sharing method using clipboard
  // const fallbackShare = async (link) => {
  //   try {
  //     // Try using clipboard API
  //     await navigator.clipboard.writeText(link);
  //     // Show toast notification
  //     setShowShareToast(true);
  //     setTimeout(() => setShowShareToast(false), 3000);
  //     console.log('Copied to clipboard successfully');
  //   } catch (clipboardError) {
  //     console.error('Clipboard copy failed:', clipboardError);
      
  //     // If clipboard fails too, show manual copy alert
  //     const textArea = document.createElement('textarea');
  //     textArea.value = link;
  //     textArea.style.position = 'fixed';  // Prevent scrolling to bottom
  //     document.body.appendChild(textArea);
  //     textArea.focus();
  //     textArea.select();
      
  //     try {
  //       // Use older document.execCommand method as final fallback
  //       const successful = document.execCommand('copy');
  //       if (successful) {
  //         setShowShareToast(true);
  //         setTimeout(() => setShowShareToast(false), 3000);
  //       } else {
  //         alert(`Copy this link manually: ${link}`);
  //       }
  //     } catch (execError) {
  //       alert(`Copy this link manually: ${link}`);
  //     }
      
  //     document.body.removeChild(textArea);
  //   }
  // };
  
  return (
    <div className="dashboard">
      <div className="dashboard-header">
        <h1>Hello, {user.name}!</h1>
      </div>

      <div className="japan-trip-section">
        <div className="header-row" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '0' }}>
          <span className="label">Special Event</span>
          <div className="countdown-pill" style={{ 
            background: 'rgba(127, 90, 240, 0.15)', 
            color: 'var(--primary-purple)', 
            borderRadius: '16px', 
            padding: '4px 10px', 
            fontSize: '12px', 
            fontWeight: '500',
            display: 'flex',
            alignItems: 'center',
            gap: '4px'
          }}>
            <TimerIcon size={12} />
            <span>Ends May 4, 2025</span>
          </div>
        </div>
        <div style={{ padding: '0' }}>
          <h2 style={{ marginTop: '4px', paddingLeft: '0', textAlign: 'left', width: '100%' }}>Japan Trip Draw</h2>
          <p className="event-description" style={{ paddingLeft: '0', textAlign: 'left' }}>Win a round-trip ticket to Japan! 🎌 Complete these challenges to enter the draw:</p>
        </div>
        
        <div className="challenge-boxes">
          <div className="challenge-box">
            <div className="challenge-header" style={{ paddingLeft: '0' }}>
              <h3>14-Day Streak</h3>
            </div>
            <p>Complete one training per day on Mina and send us feedback on Instagram (@getmina.co) for 14 days!</p>
            <div className="challenge-progress">
              <Link 
                to="/study_plan" 
                className="challenge-action"
              >
                Start Trainings
              </Link>
            </div>
          </div>
          
          <div className="challenge-box">
            <div className="challenge-header" style={{ paddingLeft: '0' }}>
              <h3>Share on Social Media</h3>
            </div>
            <p>Share a screenshot of your training on Instagram or Reddit and tag us! @getmina ✨</p>
            <div style={{ display: 'inline-block' }}>
              <a 
                href="https://www.instagram.com/getmina.co/" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="challenge-action"
                style={{ marginRight: '2px' }}
              >
                Instagram
              </a>
              <a 
                href="https://www.reddit.com/r/minajlpt/" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="challenge-action"
              >
                Reddit
              </a>
            </div>
          </div>
          
          <div className="challenge-box" style={{ background: 'rgba(255, 255, 255, 0.8)', borderColor: 'rgba(127, 90, 240, 0.2)' }}>
            <div className="challenge-header" style={{ paddingLeft: '0' }}>
              <h3>Premium Bonus!</h3>
            </div>
            <p>Premium users get 4 extra chances of being selected for the Japan trip! ✈️</p>
            {!user.isPremium && (
              <div className="challenge-progress">
                <a 
                  href="https://buy.stripe.com/5kA154aaHaVcbGU145" 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="challenge-action" 
                  style={{ background: 'rgba(127, 90, 240, 0.1)', color: '#7F5AF0' }}
                >
                  Upgrade to Premium
                </a>
              </div>
            )}
            {user.isPremium && (
              <div className="challenge-progress">
                <div 
                  className="challenge-action" 
                  style={{ 
                    background: 'rgba(127, 90, 240, 0.1)', 
                    color: '#7F5AF0', 
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center',
                    cursor: 'default'
                  }}
                >
                  <CheckIcon size={16} style={{ marginRight: '4px', color: '#7F5AF0' }} />
                  You're Premium!
                </div>
              </div>
            )}
          </div>
        </div>
        
        {/* Entry button removed */}
      </div>

      <div className="japan-trip-section" style={{ marginTop: '24px', background: 'rgba(127, 90, 240, 0.05)', border: '1px solid rgba(127, 90, 240, 0.15)' }}>
        <div className="header-row" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '0' }}>
          <span className="label" style={{ color: '#7F5AF0' }}>Premium Benefits</span>
        </div>
        <div style={{ padding: '0' }}>
          <h2 style={{ marginTop: '4px', paddingLeft: '0', textAlign: 'left', width: '100%', color: '#7F5AF0' }}>Unlock Premium Features</h2>
          <p className="event-description" style={{ paddingLeft: '0', textAlign: 'left' }}>Enhance your learning experience with these exclusive premium benefits:</p>
        </div>
        
        <div className="challenge-boxes">
          <div className="challenge-box" style={{ background: 'rgba(255, 255, 255, 0.8)', borderColor: 'rgba(127, 90, 240, 0.2)' }}>
            <div className="challenge-header" style={{ paddingLeft: '0' }}>
              <h3>Complete Learning System</h3>
            </div>
            <p>Unlimited personalized training with 2,000+ curated questions, plus earn full JLPT-style mock exams for every 3 sessions you complete to simulate real test conditions!</p>
          </div>
          
          <div className="challenge-box" style={{ background: 'rgba(255, 255, 255, 0.8)', borderColor: 'rgba(127, 90, 240, 0.2)' }}>
            <div className="challenge-header" style={{ paddingLeft: '0' }}>
              <h3>Expert Support</h3>
            </div>
            <p>Regular 1-on-1 coaching sessions with JLPT specialists, detailed performance analyses, and personalized study plans tailored to your learning style and goals!</p>
          </div>
          
          <div className="challenge-box" style={{ background: 'rgba(255, 255, 255, 0.8)', borderColor: 'rgba(127, 90, 240, 0.2)' }}>
            <div className="challenge-header" style={{ paddingLeft: '0' }}>
              <h3>Premium Privileges</h3>
            </div>
            <p>Early access to new features, priority support, bonus study materials, and exclusive events! Join our community of dedicated learners!</p>
          </div>
        </div>
      </div>
      
      
      {/* Toast notification for link copied */}
      {showShareToast && (
        <div className="toast-notification">
          <CheckIcon size={16} />
          <span>Referral link copied to clipboard!</span>
        </div>
      )}
    </div>
  );
};

export default DashboardView;