import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import '../css/ReviewExam.css';
import BeatLoader from 'react-spinners/BeatLoader';
import mixpanel from '../mixpanel';
import ResultsReview from '../components/common/Question/components/ResultsReview';
// import { Check, X } from 'lucide-react';

function ReviewPersonalizedExam() {
    const { exam_id } = useParams();
    const navigate = useNavigate();
    const [examData, setExamData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchExamReview = async () => {
            try {
                setLoading(true);
                const jwt = localStorage.getItem('jwt');
                const apiUrl = exam_id === "diagnostic" 
                    ? `https://api.getmina.co/api/v1/panel/diagnostic/user/responses` 
                    : `https://api.getmina.co/api/v1/panel/personalized-exam/teacher/${exam_id}/responses`;

                const response = await fetch(apiUrl, {
                    method: 'GET',
                    headers: { 'Authorization': `Bearer ${jwt}`, 'Content-Type': 'application/json' },
                });
                const data = await response.json();
                console.log('API Response:', data);
                setExamData(data);
                mixpanel.track('Review Exam', {
                    exam_id: exam_id,
                    score: calculateScore(data)
                });
            } catch (error) {
                console.error('Error fetching review:', error);
                setError('Failed to fetch exam review.');
            }
            setLoading(false);
        };

        fetchExamReview();
    }, [exam_id]);

    function calculateScore(responses) {
        if (!responses || responses.length === 0) return '0/0';
        const correctAnswers = responses.filter(r => r.is_correct).length;
        return `${correctAnswers}/${responses.length}`;
    }

    const calculateTotalTime = (responses) => {
        if (!responses || responses.length === 0) return 0;
        return responses.reduce((total, response) => total + (response.time_taken || 0), 0);
    };

    const handleQuestionClick = (questionId) => {
        // Find the index of the question with this ID
        const index = examData.findIndex(q => q.question_id === questionId);
        // Navigate to our new ModernReviewQuestion component
        navigate(`/review-question/${exam_id}`, { state: { examData, currentContentIndex: index } });
    };

    const handleBackToDashboard = () => {
        navigate('/');
    };

    if (loading) return <div className="loader-container"><BeatLoader color="#3E00EF" /></div>;
    if (error) return <div>{error}</div>;
    if (!examData || examData.length === 0) return <div>No exam data available.</div>;

    // Format questions for the ResultsReview component
    const formattedQuestions = examData.map((response) => ({
        id: response.question_id,
        text: response.question_text,
        isCorrect: response.is_correct,
        isBookmarked: false, // We can implement bookmarking later
        answers: [{ isCorrect: response.is_correct }] // Match the structure expected by new_front design
    }));

    // Calculate total time spent
    const totalTime = calculateTotalTime(examData);

    return (
        <ResultsReview 
            questions={formattedQuestions}
            performanceData={{
                timeSpent: totalTime,
                streak: 0 // We can implement streak later
            }}
            onQuestionClick={handleQuestionClick}
            onBackToDashboard={handleBackToDashboard}
        />
    );
}

export default ReviewPersonalizedExam;