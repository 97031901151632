import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { 
  Menu, 
  X, 
  Home, 
  Book, 
  MessageCircle, 
  Settings,
  Star,
  Youtube,
  BarChart2
} from 'lucide-react';
import '../css/Sidebar.css';
import Mina from '../assets/images/mina.png';

function Sidebar({ 
  stages, 
  currentStageIndex, 
  onStageClick, 
  getStageClassName, 
  getStageContent, 
  hideTrainings = false,
  isMobileMenuOpen,
  onMobileMenuClose,
  onMobileMenuToggle,
  onLogout,
  onGetFullAccess,
  userStatus
}) {
  const location = useLocation();
  // Streak state removed
  const [, setUserData] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [showSecretAdmin, setShowSecretAdmin] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      const jwt = localStorage.getItem('jwt');
      try {
        // Streak fetching removed

        // Fetch user data
        const userResponse = await fetch("https://api.getmina.co/api/v1/auth/me", {
          headers: { 'Authorization': `Bearer ${jwt}` },
          method: "GET",
        });
        const userData = await userResponse.json();
        setUserData(userData);
        
        // Check for secret admin key in localStorage
        const hasSecretAccess = localStorage.getItem('secretAdminAccess');
        if (hasSecretAccess === 'true') {
          setShowSecretAdmin(true);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
    
    // Secret key combination detection
    const secretCode = [83, 69, 67, 82, 69, 84]; // "SECRET"
    let keyPressBuffer = [];
    
    const handleKeyPress = (e) => {
      keyPressBuffer.push(e.keyCode);
      keyPressBuffer = keyPressBuffer.slice(-6);
      
      if (keyPressBuffer.join(',') === secretCode.join(',')) {
        localStorage.setItem('secretAdminAccess', 'true');
        setShowSecretAdmin(true);
      }
    };
    
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    if (onMobileMenuToggle) {
      onMobileMenuToggle();
    } else if (onMobileMenuClose) {
      onMobileMenuClose();
    }
    document.body.style.overflow = !isOpen ? 'hidden' : 'unset';
  };

  const closeMenu = () => {
    setIsOpen(false);
    if (onMobileMenuClose) {
      onMobileMenuClose();
    }
    document.body.style.overflow = 'unset';
  };

  const navItems = [
    { icon: <Home size={18} />, label: "Home", path: "/" },
    { icon: <Book size={18} />, label: "My Trainings", path: "/study_plan" },
    { icon: <MessageCircle size={18} />, label: "Chat with Mina", path: "/ask-mina" },
  ];
  
  // Add secret admin route if activated
  if (showSecretAdmin) {
    navItems.push({ icon: <BarChart2 size={18} />, label: "Analytics", path: "/DiagnosticExams" });
  }

  return (
    <>
      <button className="menu-toggle" onClick={toggleMenu} aria-label="Toggle menu">
        {isOpen ? <X size={24} /> : <Menu size={24} />}
      </button>

      {isOpen && <div className="sidebar-overlay" onClick={closeMenu} />}

      <aside className={`sidebar ${isOpen || isMobileMenuOpen ? 'open' : ''}`}>
        <div className="sidebar-content">
          <div className="sidebar-header">
            <div className="logo-wrapper">
              <img src={Mina} alt="Mina" className="logo" />
              <span className="logo-text">Mina</span>
            </div>
          </div>

          <nav className="nav-items">
            {navItems.map((item) => (
              <Link
                key={item.path}
                to={item.path}
                className={`nav-item ${location.pathname === item.path ? 'active' : ''}`}
                onClick={closeMenu}
              >
                <span className="icon">{item.icon}</span>
                <span className="label">{item.label}</span>
              </Link>
            ))}
          </nav>

          <div className="sidebar-footer">
            {userStatus !== 'active' ? (
              <button 
                className="get-access-btn"
                onClick={onGetFullAccess}
              >
                <span className="icon"><Star size={18} /></span>
                <span className="get-access-text">Get full access</span>
              </button>
            ) : (
              <div className="premium-indicator">
                <span className="icon"><Star size={18} /></span>
                <span className="premium-text">Premium User</span>
              </div>
            )}
            <Link to="/settings" className="settings-nav-item" onClick={closeMenu}>
              <span className="icon"><Settings size={18} /></span>
              <span className="label">Settings</span>
            </Link>
            
            <div className="sidebar-resources">
              
              <a 
                href="https://www.youtube.com/@getmina" 
                className="resource-nav-item"
                target="_blank" 
                rel="noopener noreferrer"
                onClick={closeMenu}
              >
                <span className="icon"><Youtube size={18} /></span>
                <span className="label">Our Youtube Channel</span>
              </a>
            </div>
            
            <button 
              className="logout-button"
              onClick={onLogout}
            >
              Logout
            </button>
          </div>
        </div>
      </aside>
    </>
  );
}

export default Sidebar;