import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import '../css/ReviewExam.css';
import BeatLoader from 'react-spinners/BeatLoader';
import mixpanel from '../mixpanel';
// import timeIcon from './assets/icons/time.png';
import cancelIcon from '../assets/icons/cancel.png';

function ReviewExam() {
    const { exam_id } = useParams();
    const navigate = useNavigate();
    const [examData, setExamData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchExamReview = async () => {
            try {
                setLoading(true);
                const jwt = localStorage.getItem('jwt');
                const response = await fetch('https://api.getmina.co/api/v1/panel/review_exam', {
                    method: 'POST',
                    headers: { 'Authorization': `Bearer ${jwt}`, 'Content-Type': 'application/json' },
                    body: JSON.stringify({ exam_id: exam_id })
                });
                const data = await response.json();
                console.log("Exam review data:", data);
                setExamData(data);
                mixpanel.track('Review Exam', {
                    date_taken: data.date_taken,
                    score: data.score
                });
            } catch (error) {
                console.error('Error fetching review:', error);
                setError('Failed to fetch exam review.');
            }
            setLoading(false);
        };

        fetchExamReview();
    }, [exam_id]);
    const handleRedoExam = async () => {
        try {
            const jwt = localStorage.getItem('jwt');
            const response = await fetch('https://api.getmina.co/api/v1/panel/redo-exam', {
                method: 'POST',
                headers: { 'Authorization': `Bearer ${jwt}`, 'Content-Type': 'application/json' },
                body: JSON.stringify({ exam_id: exam_id })
            });
            const result = await response.json();
            if (!response.ok) throw new Error(result.message || 'Failed to redo the exam');
            alert('Exam has been reset. Good luck!');
            navigate(`/exam/${exam_id}`);
            mixpanel.track('Redo Exam', {
                exam_id: exam_id,
            });
        } catch (error) {
            console.error('Error redoing the exam:', error.message);
            alert('Failed to redo the exam.');
        }
    };

    // const calculateTotalTime = (questions) => {
    //     if (!questions || questions.length === 0) return 0;
    //     return questions.reduce((total, question) => total + (question.time_taken || 0), 0);
    // };

    // const formatTime = (totalSeconds) => {
    //     const hours = Math.floor(totalSeconds / 3600);
    //     const minutes = Math.floor((totalSeconds % 3600) / 60);
    //     const seconds = totalSeconds % 60;
    //     return [hours, minutes, seconds]
    //         .map(v => v < 10 ? "0" + v : v)
    //         .join(":");
    // };

    const handleQuestionClick = (question, index) => {
        navigate(`/review-exam-question/${exam_id}`, { state: { examData, currentContentIndex: index } });
    };

    const handleCancelClick = () => {
        navigate('/');
    };

    if (loading) return <div className="loader-container"><BeatLoader color="#3E00EF" /></div>;
    if (error) return <div>{error}</div>;
    if (!examData) return <div>No exam data available.</div>;

    // const totalTime = calculateTotalTime(examData.questions);
    // const formattedTime = formatTime(totalTime);

    return (
        <div className="page">
            <div onClick={handleCancelClick} aria-label="cancel" className="cancel-button">
                <img src={cancelIcon} alt="Cancel" />
            </div>
            <div className="review-exam">
                <div className="header-section">
                    <div className="header-content">
                        <div className="study-results-title">Study results</div>
                        <div className="review-quiz-completed">Review Quiz completed!</div>
                    </div>
                </div>
                <div className="stats-container">
                    {/* <div className="stat-box">
                        <h3>Total study time</h3>
                        <div className="stat-value">
                            <img src={timeIcon} alt="Time icon" />
                            {formattedTime}
                        </div>
                    </div> */}
                    <div className="stat-box">
                        <h3>Learning history</h3>
                        <div className="score-container">
                            <div className="score-circle">
                                <span className="score">{examData.score}%</span>
                            </div>
                            <span className="score-label">Correct answers</span>
                        </div>
                    </div>
                </div>
                <h3>Answered question results</h3>
                <br></br>
                <div className="exam-responses">
                    {examData.questions.map((question, index) => (
                        <div key={index} className="response-card" onClick={() => handleQuestionClick(question, index)}>
                            <div className="question-header">
                                <h4>Question {index + 1}</h4>
                                {/* <span className="time">{question.time_taken}s</span> */}
                            </div>
                            <p className="question-text">{question.question_text}</p>
                            <div className="answer-status">
                                {question.is_correct ? (
                                    <svg className="status-icon correcto" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z" />
                                    </svg>
                                ) : (
                                    <svg className="status-icon incorrecto" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" />
                                    </svg>
                                )}
                            </div>
                        </div>
                    ))}
                      
            </div>
            <div className="button-container">
                    <button onClick={handleRedoExam} className="redo-exam-button">Redo Exam</button>
                    <button 
                        onClick={() => window.open('https://www.instagram.com/getmina.co/', '_blank')} 
                        className="instagram-feedback-button"
                    >
                        Send Feedback on Instagram
                    </button>
                </div>
        </div>
              
           
        </div>
    );
}

export default ReviewExam;