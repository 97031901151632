import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../css/Login.css';

import {
  Box,
  TextField,
  Button,
  Typography,
  Container,
  Radio,
  FormControlLabel,
  FormGroup,
  // Link as MuiLink,
  CssBaseline,
  Alert,
} from '@mui/material';
import { styled } from '@mui/system';
import Logo from '../assets/images/logo.png';
import BeatLoader from 'react-spinners/BeatLoader';

const StyledContainer = styled(Container)({
  margin: '160px auto 40px',
  backgroundColor: '#FFFFFF',
  padding: '40px',
  borderRadius: '16px',
  boxShadow: '0 8px 24px rgba(147, 51, 234, 0.1)',
  textAlign: 'center',
  position: 'relative',
  maxWidth: '500px',
  width: 'calc(100% - 48px)',
  boxSizing: 'border-box',
  overflow: 'visible',
});

const StyledForm = styled('form')({
  width: '100%',
  marginTop: '2rem',
});

const StyledTextField = styled(TextField)({
  width: '100%',
  marginBottom: '24px',
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
    backgroundColor: '#F9FAFB',
    transition: 'all 0.2s',
    '& fieldset': {
      borderColor: '#E5E7EB',
    },
    '&:hover': {
      backgroundColor: '#F3F4F6',
      '& fieldset': {
        borderColor: '#D1D5DB',
      },
    },
    '&.Mui-focused': {
      backgroundColor: '#FFFFFF',
      boxShadow: '0 0 0 2px rgba(147, 51, 234, 0.1)',
      '& fieldset': {
        borderColor: '#9333EA',
        borderWidth: '1px',
      },
    },
  },
  '& .MuiInputBase-input': {
    padding: '16px',
    fontSize: '15px',
  },
  '& .MuiInputLabel-root': {
    fontSize: '15px',
  },
});

const FieldTitle = styled(Typography)({
  textAlign: 'left',
  marginBottom: '8px',
  fontWeight: '500',
  fontSize: '15px',
  color: '#374151',
  fontFamily: 'Inter, sans-serif',
});

const StyledButton = styled(Button)({
  marginTop: '1.5rem',
  marginBottom: '1.5rem',
  backgroundColor: '#9333EA',
  color: 'white',
  borderRadius: '12px',
  padding: '14px',
  fontWeight: 600,
  fontSize: '16px',
  textTransform: 'none',
  letterSpacing: 0,
  boxShadow: '0 4px 10px rgba(147, 51, 234, 0.2)',
  transition: 'all 0.2s ease',
  '&:hover': {
    backgroundColor: '#7928CA',
    boxShadow: '0 6px 15px rgba(147, 51, 234, 0.3)',
    transform: 'translateY(-2px)',
  },
  '&.Mui-disabled': {
    backgroundColor: '#E9D5FF',
    color: '#fff',
  },
});

export default function SignUp() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
    agreeToAll: false,
    termsOfUse: false,
    privacyPolicy: false,
    marketingConsent: false,
  });
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (event) => {
    const { name, value, checked, type } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' || type === 'radio' ? checked : value
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!formData.firstName || !formData.lastName || !formData.username || !formData.email || !formData.password) {
      setErrorMessage('All fields are required.');
      return;
    }

    setLoading(true);
    setErrorMessage('');

    const payload = {
      email: formData.email,
      firstname: formData.firstName,
      lastname: formData.lastName,
      level: "", // Empty so it can be set during the target level step
      password: formData.password,
      username: formData.username
    };

    try {
      const response = await fetch('https://api.getmina.co/api/v1/auth/create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      });

      const responseData = await response.json();
      setLoading(false);

      if (response.ok) {
        localStorage.setItem('jwt', responseData.access);
        console.log('Registration successful:', responseData);
        navigate('/email-verification'); // Redirect to email verification page
      } else {
        setErrorMessage(responseData.message || 'Failed to register');
      }
    } catch (error) {
      setLoading(false);
      setErrorMessage('Error during registration: ' + error.message);
    }
  };

  return (
    <>
      <Box className="logo-container-box">
        <img src={Logo} alt="Mina Logo" className="mina-logo" />
      </Box>
      <StyledContainer component="main">
        <CssBaseline />
        <Box sx={{ width: '100%' }}>
          <Typography 
            component="h1" 
            variant="h5" 
            sx={{ 
              textAlign: 'center', 
              fontWeight: 600, 
              fontSize: '28px', 
              color: '#111827',
              marginBottom: '24px',
              fontFamily: 'Inter, sans-serif'
            }}
          >
            Sign up
          </Typography>
          {errorMessage && (
            <Alert severity="error" sx={{ width: '100%', marginBottom: 2 }}>
              {errorMessage}
            </Alert>
          )}
          <StyledForm onSubmit={handleSubmit}>
            <FieldTitle>First Name</FieldTitle>
            <StyledTextField
              required
              fullWidth
              id="firstName"
              name="firstName"
              autoComplete="given-name"
              autoFocus
              value={formData.firstName}
              onChange={handleChange}
              placeholder="First Name"
            />
            <FieldTitle>Last Name</FieldTitle>
            <StyledTextField
              required
              fullWidth
              id="lastName"
              name="lastName"
              autoComplete="family-name"
              value={formData.lastName}
              onChange={handleChange}
              placeholder="Last Name"
            />
            <FieldTitle>Username</FieldTitle>
            <StyledTextField
              required
              fullWidth
              id="username"
              name="username"
              autoComplete="username"
              value={formData.username}
              onChange={handleChange}
              placeholder="Username"
            />
            <FieldTitle>Email Address</FieldTitle>
            <StyledTextField
              required
              fullWidth
              id="email"
              name="email"
              autoComplete="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Email Address"
            />
            <FieldTitle>Password</FieldTitle>
            <StyledTextField
              required
              fullWidth
              name="password"
              type="password"
              id="password"
              autoComplete="new-password"
              value={formData.password}
              onChange={handleChange}
              placeholder="Password (minimum 8 characters)"
            />
            <FieldTitle>Confirm Password</FieldTitle>
            <StyledTextField
              required
              fullWidth
              name="confirmPassword"
              type="password"
              id="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleChange}
              placeholder="Confirm Password"
            />
            <FormGroup>
              {/* <FormControlLabel
                control={<Radio checked={formData.agreeToAll} onChange={handleChange} name="agreeToAll" />}
                label="Agree to all"
              /> */}
              {/* <FormControlLabel
                control={<Radio checked={formData.termsOfUse} onChange={handleChange} name="termsOfUse" />}
                label={<>
               Terms of Use
                  <MuiLink href="#" sx={{ marginLeft: 1 }}>›</MuiLink>
                </>}
              />
              <FormControlLabel
                control={<Radio checked={formData.privacyPolicy} onChange={handleChange} name="privacyPolicy" />}
                label={<>
                  Privacy Policy
                  <MuiLink href="#" sx={{ marginLeft: 1 }}>›</MuiLink>
                </>} */}
              {/* /> */}
              <FormControlLabel
                control={
                  <Radio 
                    checked={formData.marketingConsent} 
                    onChange={handleChange} 
                    name="marketingConsent"
                    sx={{
                      color: '#D1D5DB',
                      '&.Mui-checked': {
                        color: '#9333EA',
                      },
                    }}
                  />
                }
                label={
                  <Typography sx={{ 
                    fontSize: '14px', 
                    color: '#4B5563',
                    fontFamily: 'Inter, sans-serif'
                  }}>
                    Agree to receive push and marketing/event notifications
                  </Typography>
                }
              />
            </FormGroup>
            <StyledButton
              type="submit"
              fullWidth
              variant="contained"
              disabled={loading 
                // || !formData.termsOfUse || !formData.privacyPolicy

              }
            >
              {loading ? <BeatLoader color="#fff" size={10} /> : 'Sign Up'}
            </StyledButton>
            <Box sx={{ textAlign: 'center', marginTop: '16px' }}>
              <Typography variant="body2" sx={{ color: '#374151', fontSize: '14px', fontFamily: 'Inter, sans-serif' }}>
                Already have an account?{' '}
                <Link to="/login" style={{ 
                  textDecoration: 'none', 
                  color: '#9333EA', 
                  fontWeight: 600,
                  transition: 'color 0.2s ease'
                }}>
                  Log in
                </Link>
              </Typography>
            </Box>
          </StyledForm>
        </Box>
      </StyledContainer>
    </>
  );
}