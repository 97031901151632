// SessionComplete.js
import React from 'react';
import './SessionComplete.css';

const SessionComplete = ({ 
  score = 0, 
  timeInSeconds = 0,
  onViewResults,
  buttonText = "View results"
}) => {
  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs.toString().padStart(2, '0')}`;
  };

  return (
    <div className="session-complete-wrapper">
      <div className="confetti-animation"></div>
      
      <div className="session-content">
        <h1 className="session-title">Session complete!</h1>
        
        <div className="metrics-grid">
          <div className="metric-card">
            <div className="metric-header">
              <div className="metric-icon">🏆</div>
              <div className="metric-label">Score</div>
            </div>
            <div className="metric-value">{score}%</div>
          </div>

          <div className="metric-card">
            <div className="metric-header">
              <div className="metric-icon">⏱️</div>
              <div className="metric-label">Time</div>
            </div>
            <div className="metric-value">{formatTime(timeInSeconds)}</div>
          </div>
        </div>

        <button className="view-results-button" onClick={onViewResults}>
          {buttonText}
        </button>
      </div>
    </div>
  );
};

export default SessionComplete;