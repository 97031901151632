// ===============================================
// QuestionLayout.js
// Core layout component for all question views
// ===============================================
import React from 'react';
import { Timer, X as CloseIcon } from 'lucide-react';
import './QuestionLayout.css';

const QUESTION_MODES = {
  SOLVING: 'solving',
  REVIEW: 'review'
};

const QuestionLayout = ({
  header,
  footer,
  children,
  timer,
  totalQuestions,
  currentQuestion,
  onExit,
  className = '',
  isAnswered = false,
  mode = QUESTION_MODES.SOLVING
}) => {
  const remainingQuestions = totalQuestions - currentQuestion;
  
  return (
    <div 
      className={`question-layout ${className} ${isAnswered ? 'answered' : ''}`}
      data-mode={mode}
    >
      <div className="question-header">
        <button 
          className="exit-btn" 
          onClick={(e) => {
            e.stopPropagation();
            if (onExit) onExit(e);
          }}
          aria-label={mode === QUESTION_MODES.REVIEW ? "Back to results" : "Exit training"}
        >
          <CloseIcon size={20} />
        </button>
        
        {header || (
          <>
            {mode === QUESTION_MODES.SOLVING && (
              <div className="timer-container">
                <Timer size={16} className="timer-icon" />
                <span>{timer || '00:00'}</span>
              </div>
            )}
            <div className="questions-remaining">
              {remainingQuestions === 0 
                ? '0 questions remaining'
                : `${remainingQuestions} question${remainingQuestions === 1 ? '' : 's'} remaining`
              }
            </div>
          </>
        )}
      </div>

      <div className="question-container">
        <div className="question-content">
          {children}
        </div>
      </div>

      <div className="question-footer">
        {footer}
      </div>

      {mode === QUESTION_MODES.REVIEW && (
        <div className="mode-indicator">
          Review Mode
        </div>
      )}
    </div>
  );
};

export { QUESTION_MODES };
export default QuestionLayout;