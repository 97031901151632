import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/Login.css';
import Logo from '../assets/images/logo.png';
import {
  Box,
  Container,
  Typography,
  Button,
  Paper,
  CssBaseline,
  styled
} from '@mui/material';

const PageContainer = styled('div')({
  minHeight: '100vh',
  width: '100%',
  background: 'linear-gradient(135deg, #9333EA 0%, #4F46E5 100%)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  fontFamily: 'Inter, sans-serif',
});

const StepCard = styled(Paper)({
  maxWidth: '500px',
  width: 'calc(100% - 48px)',
  margin: '120px auto 40px',
  padding: '40px',
  borderRadius: '16px',
  boxShadow: '0 8px 40px rgba(0, 0, 0, 0.12)',
  boxSizing: 'border-box',
  overflow: 'visible',
  backgroundColor: '#ffffff',
  position: 'relative',
});

const StepIndicator = styled(Typography)({
  fontFamily: 'Inter, sans-serif',
  fontSize: '14px',
  fontWeight: 600,
  color: '#6B7280',
  marginBottom: '16px',
});

const ExperienceButton = styled(Button)(({ selected }) => ({
  width: '100%',
  padding: '16px',
  marginBottom: '16px',
  borderRadius: '12px',
  border: '1px solid',
  borderColor: selected ? '#9333EA' : '#E5E7EB',
  backgroundColor: selected ? 'rgba(147, 51, 234, 0.08)' : '#FFFFFF',
  color: selected ? '#9333EA' : '#111827',
  fontWeight: 500,
  fontSize: '15px',
  textTransform: 'none',
  justifyContent: 'flex-start',
  textAlign: 'left',
  boxShadow: selected ? '0 2px 8px rgba(147, 51, 234, 0.12)' : 'none',
  transition: 'all 0.2s ease',
  '&:hover': {
    backgroundColor: selected ? 'rgba(147, 51, 234, 0.12)' : '#F9FAFB',
    borderColor: selected ? '#9333EA' : '#D1D5DB',
  },
}));

function JLPTExperienceStep() {
  const [hasExperience, setHasExperience] = useState(null);
  const navigate = useNavigate();

  const handleExperienceClick = (experience) => {
    setHasExperience(experience);
    localStorage.setItem('jlptExperience', experience);
    navigate('/target-level');
  };

  return (
    <PageContainer>
      <CssBaseline />
      
      <Box className="logo-container-box" sx={{ 
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        padding: '16px 24px',
        backgroundColor: 'white',
        boxShadow: '0 2px 12px rgba(0, 0, 0, 0.05)',
        display: 'flex',
        alignItems: 'center',
        zIndex: 1000,
        height: '70px'
      }}>
        <img src={Logo} alt="Mina Logo" style={{ height: '40px' }} />
      </Box>
      
      <Container maxWidth="md" sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <StepCard elevation={0}>
          <StepIndicator>Step 1 of 2</StepIndicator>
          
          <Typography 
            variant="h1" 
            sx={{ 
              fontSize: '28px', 
              fontWeight: 700, 
              color: '#111827', 
              marginBottom: '16px',
              fontFamily: 'Inter, sans-serif'
            }}
          >
            Have you ever taken the JLPT before?
          </Typography>
          
          <Typography 
            variant="body1" 
            sx={{ 
              fontSize: '16px', 
              color: '#6B7280', 
              marginBottom: '32px',
              lineHeight: 1.5,
              fontFamily: 'Inter, sans-serif'
            }}
          >
            Mina recommends customized lectures and study courses depending on your experience with the JLPT.
          </Typography>
          
          <Box sx={{ marginTop: '16px' }}>
            <ExperienceButton 
              selected={hasExperience === true}
              onClick={() => handleExperienceClick(true)}
              fullWidth
              disableElevation
            >
              Yes, I have taken the JLPT before
            </ExperienceButton>
            
            <ExperienceButton 
              selected={hasExperience === false}
              onClick={() => handleExperienceClick(false)}
              fullWidth
              disableElevation
            >
              No, I have never taken the JLPT before
            </ExperienceButton>
          </Box>
        </StepCard>
      </Container>
    </PageContainer>
  );
}

export default JLPTExperienceStep;