import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import BeatLoader from 'react-spinners/BeatLoader';
import '../css/Login.css';
import Logo from '../assets/images/logo.png';
import {
  Box,
  Container,
  Typography,
  Button,
  Paper,
  CssBaseline,
  styled
} from '@mui/material';

const PageContainer = styled('div')({
  minHeight: '100vh',
  width: '100%',
  background: 'linear-gradient(135deg, #9333EA 0%, #4F46E5 100%)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  fontFamily: 'Inter, sans-serif',
});

const StepCard = styled(Paper)({
  maxWidth: '500px',
  width: 'calc(100% - 48px)',
  margin: '120px auto 40px',
  padding: '40px',
  borderRadius: '16px',
  boxShadow: '0 8px 40px rgba(0, 0, 0, 0.12)',
  boxSizing: 'border-box',
  overflow: 'visible',
  backgroundColor: '#ffffff',
  position: 'relative',
  textAlign: 'center',
});

const StepLabel = styled(Typography)({
  fontFamily: 'Inter, sans-serif',
  fontSize: '14px',
  fontWeight: 600,
  color: '#9333EA',
  marginBottom: '16px',
  textTransform: 'uppercase',
  letterSpacing: '0.05em',
});

const StartButton = styled(Button)(({ disabled }) => ({
  width: '100%',
  padding: '16px',
  marginTop: '40px',
  borderRadius: '12px',
  backgroundColor: disabled ? '#E9D5FF' : '#9333EA',
  color: 'white',
  fontWeight: 600,
  fontSize: '16px',
  textTransform: 'none',
  boxShadow: disabled ? 'none' : '0 4px 6px rgba(147, 51, 234, 0.25)',
  transition: 'all 0.2s ease',
  '&:hover': {
    backgroundColor: disabled ? '#E9D5FF' : '#7928CA',
    boxShadow: disabled ? 'none' : '0 6px 10px rgba(147, 51, 234, 0.3)',
    transform: disabled ? 'none' : 'translateY(-2px)',
  },
}));

function LevelTestIntro() {
  // const navigate = useNavigate();
  const targetLevel = localStorage.getItem('selectedLevel');
  const [loading, setLoading] = useState(false);

  const handleStartTest = async () => {
    setLoading(true);
    try {
      // Set a flag that onboarding is complete
      localStorage.setItem('onboardingComplete', 'true');
      const jwt = localStorage.getItem('jwt');
      
      // First, update the user's profile with the selected target level
      try {
        // Get the user profile first to maintain existing data
        const userResponse = await fetch("https://api.getmina.co/api/v1/auth/me", {
          headers: { 'Authorization': `Bearer ${jwt}` },
          method: "GET"
        });
        
        if (userResponse.ok) {
          // const userData = await userResponse.json();
          
          // Update the profile with target level
          const updateResponse = await fetch('https://api.getmina.co/api/v1/auth/change-level', {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${jwt}`,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ level: targetLevel })
          });
          
          if (!updateResponse.ok) {
            console.error('Failed to update user profile with target level');
          }
        }
      } catch (updateError) {
        console.error('Error updating user profile:', updateError);
        // Continue with test even if profile update fails
      }
      
      // Now fetch the diagnostic exam for the selected level
      const response = await fetch(`https://api.getmina.co/api/v1/panel/diagnostic-exams/${targetLevel}`, {
        headers: { 'Authorization': `Bearer ${jwt}` }
      });
      const diagnosticExams = await response.json();
      
      if (Array.isArray(diagnosticExams) && diagnosticExams.length > 0) {
        const exam = diagnosticExams[0];
        if (exam.exam_id) {
          // Mark onboarding as fully complete before navigating to diagnostic exam
          localStorage.setItem('onboardingComplete', 'true');
          
          // Force reload to ensure we're using the authenticated routes with sidebar
          window.location.href = `/diagnostic-exam/${exam.exam_id}`;
        } else {
          console.error("Exam ID not found in the response");
        }
      } else {
        console.error("No diagnostic exams found or unexpected response format");
      }
    } catch (error) {
      console.error("Error fetching diagnostic exam:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageContainer>
      <CssBaseline />
      
      <Box className="logo-container-box" sx={{ 
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        padding: '16px 24px',
        backgroundColor: 'white',
        boxShadow: '0 2px 12px rgba(0, 0, 0, 0.05)',
        display: 'flex',
        alignItems: 'center',
        zIndex: 1000,
        height: '70px'
      }}>
        <img src={Logo} alt="Mina Logo" style={{ height: '40px' }} />
      </Box>
      
      <Container maxWidth="md" sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <StepCard elevation={0}>
          <StepLabel>Level Test</StepLabel>
          
          <Typography 
            variant="h1" 
            sx={{ 
              fontSize: '28px', 
              fontWeight: 700, 
              color: '#111827', 
              marginBottom: '16px',
              fontFamily: 'Inter, sans-serif'
            }}
          >
            Let's check your JLPT level!
          </Typography>
          
          <Typography 
            variant="body1" 
            sx={{ 
              fontSize: '16px', 
              color: '#6B7280', 
              marginBottom: '32px',
              lineHeight: 1.5,
              fontFamily: 'Inter, sans-serif',
              maxWidth: '400px',
              mx: 'auto'
            }}
          >
            Based on vast learning data, we provide JLPT levels and AI-customized studies to accelerate your progress.
          </Typography>
          
          <Box sx={{ 
            height: '160px', 
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '12px',
            backgroundColor: 'rgba(147, 51, 234, 0.05)',
            border: '1px dashed rgba(147, 51, 234, 0.3)',
            padding: '20px',
            marginBottom: '20px'
          }}>
            <Typography 
              variant="body1" 
              sx={{ 
                fontSize: '15px', 
                color: '#9333EA',
                fontWeight: 500,
                fontFamily: 'Inter, sans-serif',
                fontStyle: 'italic'
              }}
            >
              The test will take around 15 minutes. Your results will help us personalize your learning journey.
            </Typography>
          </Box>
          
          <StartButton
            onClick={handleStartTest}
            disabled={loading}
            disableElevation
          >
            {loading ? <BeatLoader color="#ffffff" size={10} /> : 'Start the level test'}
          </StartButton>
        </StepCard>
      </Container>
    </PageContainer>
  );
}

export default LevelTestIntro;