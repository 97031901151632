import React, { useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import {  Send, X } from 'lucide-react';
import QuestionLayout, { QUESTION_MODES } from '../components/common/Question/QuestionLayout';
import QuestionDisplay, { QUESTION_TYPES } from '../components/common/Question/components/QuestionDisplay';
// import BeatLoader from 'react-spinners/BeatLoader';
import { sendQuestionChat,  } from '../api/exams';

function ModernReviewQuestion() {
  const navigate = useNavigate();
  const location = useLocation();
  const { exam_id } = useParams();
  const [currentContentIndex, setCurrentContentIndex] = useState(location.state?.currentContentIndex || 0);
  const examData = location.state?.examData || [];
  const [showChat, setShowChat] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [messages, setMessages] = useState([]);
  const [isTyping, setIsTyping] = useState(false);

  if (examData.length === 0 || !examData[currentContentIndex]) {
    return <div className="loading-container"><div>No content available</div></div>;
  }

  const totalQuestions = examData.length;
  const currentQuestion = examData[currentContentIndex];
  const hasScript = currentQuestion?.script && currentQuestion.script !== "0";

  const handlePreviousQuestion = () => {
    if (currentContentIndex > 0) {
      setCurrentContentIndex(currentContentIndex - 1);
    }
  };

  const handleNextQuestion = () => {
    if (currentContentIndex < totalQuestions - 1) {
      setCurrentContentIndex(currentContentIndex + 1);
    } else {
      // If last question, go back to the review page
      handleBackButtonClick();
    }
  };

  const handleBackButtonClick = () => {
    navigate(`/review-personalized/${exam_id}`);
  };

  const handleAskMina = () => {
    setShowChat(true);
    // If first time opening chat, add welcome message
    if (messages.length === 0) {
      setMessages([
        { id: 1, sender: 'bot', text: 'How can I help you with this question?' }
      ]);
    }
  };

  const handleCloseChat = () => {
    setShowChat(false);
  };

  const formatText = (text) => {
    if (!text || text === '0') return '';
    return text.replace(/{{NEWLINE}}/g, '\n');
  };

  // Format the answers and question for QuestionDisplay
  let questionType = QUESTION_TYPES.SINGLE;
  let options = [];
  let questions = [];

  if (hasScript && Array.isArray(currentQuestion.questions)) {
    // Multiple questions with a reading passage
    questionType = QUESTION_TYPES.MULTIPLE;
    questions = currentQuestion.questions.map(q => ({
      id: q.question_id,
      text: q.question_text,
      options: [
        ...q.choices.map((choice, index) => ({ id: index, text: choice }))
      ],
      correctAnswer: q.correct_answer,
      explanation: q.explanation
    }));
  } else {
    // Single question
    questionType = QUESTION_TYPES.SINGLE;
    options = [
      ...(currentQuestion.choices || []).map((choice, index) => ({ id: index, text: choice }))
    ];
  }

  // For review, use the correct_answer and selected_answer to mark
  const selectedAnswers = {};
  // If we have a selected_answer, find its index
  const selectedAnswerIndex = currentQuestion.choices?.findIndex(
    choice => choice === currentQuestion.selected_answer
  );
  
  if (selectedAnswerIndex !== undefined && selectedAnswerIndex >= 0) {
    selectedAnswers[0] = selectedAnswerIndex;
  }

  return (
    <>
      <QuestionLayout
        timer=""
        totalQuestions={totalQuestions}
        currentQuestion={currentContentIndex + 1}
        onExit={handleBackButtonClick}
        isAnswered={true} // Always answered in review mode
        mode={QUESTION_MODES.REVIEW}
        footer={
          <>
            <button 
              className="ask-mina-btn" 
              onClick={handleAskMina}
            >
              Ask Mina
            </button>
            
            <div className="navigation-buttons">
              <button 
                className="nav-btn previous" 
                disabled={currentContentIndex === 0}
                onClick={handlePreviousQuestion}
              >
                Previous
              </button>
              
              <button 
                className="nav-btn next" 
                onClick={handleNextQuestion}
              >
                {currentContentIndex === totalQuestions - 1 ? 'Finish' : 'Next'}
              </button>
            </div>
          </>
        }
      >
        <QuestionDisplay
          type={questionType}
          title={`Question ${currentContentIndex + 1}`}
          instruction={currentQuestion.instruction}
          script={!hasScript ? currentQuestion.question_text : null}
          questionText={!hasScript ? null : null}
          readingText={hasScript ? currentQuestion.script : null}
          questions={questions}
          options={options}
          selectedAnswer={selectedAnswers[0]}
          selectedAnswers={selectedAnswers}
          isAnswered={true} // Always answered in review mode
          correctAnswer={currentQuestion.correct_answer}
          explanation={currentQuestion.explanation}
          formatText={formatText}
          imageUrl={currentQuestion?.script_image?.urls?.[0]}
        />
      </QuestionLayout>

      {/* Context Chat Component */}
      <div className={`context-chat ${showChat ? 'visible' : ''}`}>
        <div className="context-chat-header">
          <h3>Chat with Mina</h3>
          <button 
            className="context-chat-close" 
            onClick={handleCloseChat}
            aria-label="Close chat"
          >
            <X size={20} />
          </button>
        </div>

        <div className="context-chat-messages">
          {messages.map((message) => (
            <div key={message.id} className={`context-chat-message ${message.sender}`}>
              {message.sender === 'bot' && (
                <div className="bot-avatar">
                  <img src="/mina.png" alt="Mina" className="avatar-logo" />
                </div>
              )}
              <div className="message-content">{message.text}</div>
            </div>
          ))}
          
          {isTyping && (
            <div className="context-chat-message bot">
              <div className="bot-avatar">
                <img src="/mina.png" alt="Mina" className="avatar-logo" />
              </div>
              <div className="message-content">
                <span className="typing-indicator">
                  <span className="dot"></span>
                  <span className="dot"></span>
                  <span className="dot"></span>
                </span>
              </div>
            </div>
          )}
        </div>

        <form 
          className="context-chat-input-container" 
          onSubmit={async (e) => {
            e.preventDefault();
            if (!inputValue.trim() || isTyping) return;
            
            // Add user message
            const userInput = inputValue;
            const userMessage = { id: Date.now(), sender: 'user', text: userInput };
            setMessages(prevMessages => [...prevMessages, userMessage]);
            setInputValue('');
            setIsTyping(true);
            
            try {
              // Prepare question context for the API
              const questionContext = {
                question_id: currentQuestion?.question_id,
                question_text: currentQuestion?.question_text,
                options: currentQuestion?.choices,
                correct_answer: currentQuestion?.correct_answer,
                explanation: currentQuestion?.explanation
              };
              
              let responseData;
              
              try {
                // Try to use our sendQuestionChat API
                responseData = await sendQuestionChat(questionContext, userInput);
                console.log('API response:', responseData);
              } catch (apiError) {
                console.error('Error calling API, falling back to mock response:', apiError);
                
                // If the API call fails, provide a meaningful fallback response
                let mockContent = `I'll help explain this question. The question is asking about "${questionContext.question_text}".`;
                
                if (questionContext.options) {
                  mockContent += `\n\nThe correct answer is "${questionContext.correct_answer}".`;
                }
                
                if (questionContext.explanation) {
                  mockContent += `\n\n${questionContext.explanation}`;
                } else {
                  mockContent += `\n\nThere is no additional explanation provided for this question. Would you like me to analyze it further?`;
                }
                
                responseData = {
                  choices: [{
                    message: {
                      content: mockContent
                    }
                  }]
                };
              }
              
              // Extract the bot's response text - handle different response formats
              let botResponseText;
              
              // Check if the response contains a JSON part
              if (typeof responseData === 'string') {
                // If the response is a string, try to split out any JSON part
                const jsonStartIndex = responseData.indexOf('{');
                if (jsonStartIndex > 0) {
                  // Extract the text part before the JSON
                  botResponseText = responseData.substring(0, jsonStartIndex).trim();
                } else {
                  botResponseText = responseData;
                }
              } else if (responseData.choices && responseData.choices[0] && responseData.choices[0].message) {
                // OpenAI-like response format
                botResponseText = responseData.choices[0].message.content;
              } else if (responseData.response) {
                // Our custom API response format
                botResponseText = responseData.response;
              } else if (responseData.text) {
                // Alternative response format
                botResponseText = responseData.text;
              } else if (responseData.history) {
                // If we have a history array from the backend
                const systemMessages = responseData.history.filter(msg => msg.role === 'system');
                // Get the last system message which should be the AI response
                if (systemMessages.length > 0) {
                  botResponseText = systemMessages[systemMessages.length - 1].content;
                } else {
                  botResponseText = "I'm sorry, I couldn't process your question properly.";
                }
              } else {
                // Fallback if the response format is unexpected
                botResponseText = "I'm sorry, but I couldn't process your question properly. Please try asking something else.";
              }
              
              // Add bot message with the AI response
              const botMessage = { 
                id: Date.now(), 
                sender: 'bot', 
                text: botResponseText
              };
              setMessages(prevMessages => [...prevMessages, botMessage]);
            } catch (error) {
              console.error('Error processing chat message:', error);
              // Fallback response in case of error
              const errorMessage = { 
                id: Date.now(), 
                sender: 'bot', 
                text: 'I apologize, but I encountered an error while processing your question. Please try again later.' 
              };
              setMessages(prevMessages => [...prevMessages, errorMessage]);
            } finally {
              setIsTyping(false);
            }
          }}
        >
          <input
            type="text"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder="Type your message..."
            disabled={isTyping}
          />
          <button 
            type="submit" 
            className="send-message" 
            disabled={!inputValue.trim() || isTyping}
            aria-label="Send message"
          >
            <Send size={20} />
          </button>
        </form>
      </div>
      
      {/* Chat Overlay */}
      <div 
        className={`context-chat-overlay ${showChat ? 'visible' : ''}`}
        onClick={handleCloseChat}
      />
    </>
  );
}

export default ModernReviewQuestion;