import React, { useState, useEffect } from 'react';
import './css/AskMinaHome.css';
import ChatView from './components/ChatView';
import Sidebar from './components/Sidebar';
import { FaBars } from 'react-icons/fa';

// import mixpanel from './mixpanel';

// const ASK_MINA_HELP_URL = "https://api.getmina.co/api/v1/panel/get_helpopen";
// const ASK_MINA_HELP_URL2 = "https://api.getmina.co/api/v1/panel/ask-mina-help";
// const NORMAL_API_URL = "https://api.getmina.co/api/v1/panel/test-convo";

function AskMinaHome({ questionId, initialChatHistory, questionnumber, onClose }) {
    // const navigate = useNavigate();
    // const [input, setInput] = useState('');
    // const [ setDisplayChatHistory] = useState(() => {
    //     if (initialChatHistory && initialChatHistory.length > 0) {
    //         return [initialChatHistory[initialChatHistory.length - 1]]; // Start with only the last message
    //     }
    //     return [];
    // });
    // const [responseHistory, setResponseHistory] = useState([]); // Store extracted "history" parts
    // const [isLoading, setIsLoading] = useState(false); // Loading state
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);
    const [userStatus, setUserStatus] = useState('');

    // const suggestions = [
    //     {
    //         title: 'Vocabulary',
    //         description: 'Interact with Mina to enhance your vocabulary, tackle word challenges, and master language nuances.'
    //     },
    //     {
    //         title: 'Grammar',
    //         description: 'Interact with Mina to enhance your vocabulary, tackle word challenges, and master language nuances.'
    //     },
    //     {
    //         title: 'Reading',
    //         description: 'Collaborate with Mina to dive deep into JLPT reading, sharpen your comprehension of short, mid-size and long passages.'
    //     },
    //     {
    //         title: 'General Information',
    //         description: 'Consult Mina for essential details and guidance during your test prep journey.'
    //     }
    // ];

    // const handleSuggestionClick = (suggestion) => {
    //     setInput(suggestion.title);
    //     sendInput(suggestion.title);
    // };

    // const sendInput = async (userInput = input) => {
    //     if (!userInput.trim() || isLoading) return;

    //     setIsLoading(true);
    //     setInput(''); // Clear input field

    //     // Add user message to chat
    //     const newUserMessage = { role: 'user', content: userInput };
    //     setDisplayChatHistory(prev => [...prev, newUserMessage]);

    //     try {
    //         const requestBody = {
    //             user_input: userInput,
    //             history: responseHistory,
    //         };

    //         mixpanel.track('Ask Mina', {
    //             user_input: userInput,
    //         });

    //         const url = responseHistory.length === 0 ? ASK_MINA_HELP_URL2 : NORMAL_API_URL;

    //         const jwt = localStorage.getItem('jwt');
    //         const response = await fetch(url, {
    //             method: "POST",
    //             headers: { 
    //                 'Authorization': `Bearer ${jwt}`, 
    //                 "Content-Type": "application/json" 
    //             },
    //             body: JSON.stringify(requestBody)
    //         });

    //         const reader = response.body.getReader();
    //         let currentDisplayContent = '';
    //         let fullResponse = '';

    //         function processText({ done, value }) {
    //             if (value) {
    //                 const chunk = new TextDecoder("utf-8").decode(value, { stream: !done });
    //                 fullResponse += chunk;

    //                 const jsonStartIndex = chunk.indexOf('{"history":');
    //                 if (jsonStartIndex !== -1) {
    //                     currentDisplayContent += chunk.substring(0, jsonStartIndex);
    //                 } else {
    //                     currentDisplayContent += chunk;
    //                 }

    //                 setDisplayChatHistory(prev => {
    //                     const newHistory = [...prev];
    //                     if (newHistory.length > 0 && newHistory[newHistory.length - 1].role === 'system') {
    //                         newHistory[newHistory.length - 1].content = currentDisplayContent;
    //                     } else {
    //                         newHistory.push({ role: 'system', content: currentDisplayContent });
    //                     }
    //                     return newHistory;
    //                 });
    //             }

    //             if (!done) {
    //                 return reader.read().then(processText);
    //             } else {
    //                 const historyMatch = fullResponse.match(/{"history":\s*\[.*\]}/);
    //                 if (historyMatch) {
    //                     const history = JSON.parse(historyMatch[0]).history;
    //                     setResponseHistory(history);
    //                 }

    //                 const historyIndex = currentDisplayContent.indexOf('{"history":');
    //                 if (historyIndex !== -1) {
    //                     currentDisplayContent = currentDisplayContent.substring(0, historyIndex);
    //                 }

    //                 setDisplayChatHistory(prev => {
    //                     const newHistory = [...prev];
    //                     if (newHistory.length > 0 && newHistory[newHistory.length - 1].role === 'system') {
    //                         newHistory[newHistory.length - 1].content = currentDisplayContent;
    //                     } else {
    //                         newHistory.push({ role: 'system', content: currentDisplayContent });
    //                     }
    //                     return newHistory;
    //                 });

    //                 setIsLoading(false);
    //             }
    //         }

    //         reader.read().then(processText);
    //     } catch (error) {
    //         console.error("Error fetching Mina's response:", error);
    //         setIsLoading(false);
    //     }
    // };

    // const handleKeyPress = (e) => {
    //     if (e.key === 'Enter') {
    //         sendInput();
    //     }
    // };

    // const handleBack = () => {
    //     onClose(displayChatHistory) ; // Pass the updated chatHistory back to the parent component
    //  };

    // Add resize listener and fetch user status
    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 768);
        };

        // Check user status
        const checkUserStatus = async () => {
            try {
                const jwt = localStorage.getItem('jwt');
                const response = await fetch("https://api.getmina.co/api/v1/auth/me", {
                    headers: { 'Authorization': `Bearer ${jwt}` },
                    method: "GET",
                });
                
                if (response.ok) {
                    const userData = await response.json();
                    setUserStatus(userData.status);
                }
            } catch (error) {
                console.error("Error checking user status:", error);
            }
        };
        
        checkUserStatus();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className="page-container">
            {isMobileView && (
                <FaBars 
                    className="mobile-menu-icon" 
                    onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                />
            )}
            
            <Sidebar 
                isMobileMenuOpen={isMobileMenuOpen}
                onMobileMenuClose={() => setIsMobileMenuOpen(false)}
                userStatus={userStatus}
                onGetFullAccess={() => window.location.href = 'https://www.getmina.co/subscribe'}
                onLogout={() => {
                    localStorage.removeItem('jwt');
                    window.location.reload();
                }}
            />
            
            <div className="main-content">
                <ChatView />
            </div>
        </div>
    );
}

export default AskMinaHome;
