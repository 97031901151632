import React from 'react';
import DiagnosticExams from './DiagnosticExams';

// This is a very simple wrapper that just renders the DiagnosticExams component directly
const DirectAdmin = () => {
  // Force authentication bypass
  React.useEffect(() => {
    console.log('Direct admin access via DirectAdmin component');
  }, []);

  return <DiagnosticExams />;
};

export default DirectAdmin;