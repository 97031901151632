import React, { useState } from 'react';
import mixpanel from '../mixpanel';
import '../css/Login.css';

// import { useNavigate } from 'react-router-dom';
import {
  Box,
  TextField,
  Button,
  Typography,
  Container,
  CssBaseline,
  Alert,
} from '@mui/material';
import { styled } from '@mui/system';
import Logo from '../assets/images/logo.png';
import BeatLoader from 'react-spinners/BeatLoader';

const StyledContainer = styled(Container)({
  margin: '160px auto 40px',
  backgroundColor: '#FFFFFF',
  padding: '40px',
  borderRadius: '16px',
  boxShadow: '0 8px 24px rgba(147, 51, 234, 0.1)',
  textAlign: 'center',
  position: 'relative',
  maxWidth: '450px',
  width: 'calc(100% - 48px)',
  boxSizing: 'border-box',
  overflow: 'visible',
});

const StyledForm = styled('form')({
  width: '100%',
  marginTop: '2rem',
});

const StyledTextField = styled(TextField)({
  width: '100%',
  marginBottom: '24px',
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
    backgroundColor: '#F9FAFB',
    transition: 'all 0.2s',
    '& fieldset': {
      borderColor: '#E5E7EB',
    },
    '&:hover': {
      backgroundColor: '#F3F4F6',
      '& fieldset': {
        borderColor: '#D1D5DB',
      },
    },
    '&.Mui-focused': {
      backgroundColor: '#FFFFFF',
      boxShadow: '0 0 0 2px rgba(147, 51, 234, 0.1)',
      '& fieldset': {
        borderColor: '#9333EA',
        borderWidth: '1px',
      },
    },
  },
  '& .MuiInputBase-input': {
    padding: '16px',
    fontSize: '15px',
  },
  '& .MuiInputLabel-root': {
    fontSize: '15px',
  },
});

const FieldTitle = styled(Typography)({
  textAlign: 'left',
  marginBottom: '8px',
  fontWeight: '500',
  fontSize: '15px',
  color: '#374151',
  fontFamily: 'Inter, sans-serif',
});

const StyledButton = styled(Button)({
  marginTop: '1.5rem',
  marginBottom: '1rem',
  backgroundColor: '#9333EA',
  color: 'white',
  borderRadius: '12px',
  padding: '14px',
  fontWeight: 600,
  fontSize: '16px',
  textTransform: 'none',
  letterSpacing: 0,
  boxShadow: '0 4px 10px rgba(147, 51, 234, 0.2)',
  transition: 'all 0.2s ease',
  '&:hover': {
    backgroundColor: '#7928CA',
    boxShadow: '0 6px 15px rgba(147, 51, 234, 0.3)',
    transform: 'translateY(-2px)',
  },
  '&.Mui-disabled': {
    backgroundColor: '#E9D5FF',
    color: '#fff',
  },
});

export default function EmailVerification() {
  // const navigate = useNavigate();
  const [otp, setOtp] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!otp) {
      setErrorMessage('OTP is required.');
      return;
    }

    setLoading(true);
    setErrorMessage('');

    const jwt = localStorage.getItem('jwt');

    try {
      const response = await fetch('https://api.getmina.co/api/v1/auth/verify-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${jwt}`
        },
        body: JSON.stringify({ otp })
      });

      const responseData = await response.json();
      setLoading(false);

      if (response.ok) {
        // console.log('Email verified successfully:', verificationData);

        const meResponse = await fetch("https://api.getmina.co/api/v1/auth/me", {
          method: "GET",
          headers: {
            'Authorization': `Bearer ${jwt}`,
            "Content-Type": "application/json"
          },
        });
        const meData = await meResponse.json();
        console.log("RESP", meData);

        // Ensure JWT is stored and user is identified
        console.log("THE JWT", jwt);
        localStorage.setItem('jwt', jwt);

        mixpanel.identify(meData.user_id);
        mixpanel.people.set({
          $name: `${meData.FirstName} ${meData.LastName}`, // Assuming these fields are returned
          $email: meData.email,  // Mixpanel's special property for email
          status: meData.status,
        });

        mixpanel.track('Register', {
          email: meData.email,
        });
      


        window.location.href = '/jlpt-experience';
      }else {
        setErrorMessage(responseData.message || 'Verification failed');
      }
    } catch (error) {
      setLoading(false);
      setErrorMessage('Error during email verification: ' + error.message);
    }
  };

  return (
    <>
      <Box className="logo-container-box">
        <img src={Logo} alt="Mina Logo" className="mina-logo" />
      </Box>
      <StyledContainer component="main">
        <CssBaseline />
        <Box sx={{ width: '100%' }}>
          <Typography 
            component="h1" 
            variant="h5" 
            sx={{ 
              textAlign: 'center', 
              fontWeight: 600, 
              fontSize: '28px', 
              color: '#111827',
              marginBottom: '24px',
              fontFamily: 'Inter, sans-serif'
            }}
          >
            Verify Email
          </Typography>
          <Typography
            variant="body1"
            sx={{
              textAlign: 'center',
              fontSize: '15px',
              color: '#6B7280',
              marginBottom: '24px',
              fontFamily: 'Inter, sans-serif',
              maxWidth: '360px',
              mx: 'auto'
            }}
          >
            Please enter the verification code sent to your email address.
          </Typography>
          {errorMessage && (
            <Alert 
              severity="error" 
              sx={{ 
                width: '100%', 
                marginBottom: 3,
                borderRadius: '12px',
                '& .MuiAlert-icon': {
                  color: '#F87171'
                },
                '& .MuiAlert-message': {
                  fontFamily: 'Inter, sans-serif',
                  fontSize: '14px'
                }
              }}
            >
              {errorMessage}
            </Alert>
          )}
          <StyledForm onSubmit={handleSubmit}>
            <FieldTitle>Verification Code</FieldTitle>
            <StyledTextField
              required
              fullWidth
              id="otp"
              name="otp"
              autoComplete="off"
              autoFocus
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              placeholder="Enter 6-digit code"
              inputProps={{
                maxLength: 6,
                style: { letterSpacing: '2px', fontWeight: 500 }
              }}
            />
            <StyledButton
              type="submit"
              fullWidth
              variant="contained"
              disabled={loading}
            >
              {loading ? <BeatLoader color="#fff" size={10} /> : 'Verify Email'}
            </StyledButton>
          </StyledForm>
        </Box>
      </StyledContainer>
    </>
  );
}




