import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Check, X } from 'lucide-react';
import './ResultsReview.css';

const ResultsReview = ({
  questions,
  performanceData,
  onQuestionClick,
  onBackToDashboard,
  onClose,
  onToggleBookmark
}) => {
  const navigate = useNavigate();
  const [showWrongOnly, setShowWrongOnly] = useState(false);
  
  // Calculate performance metrics
  const correctCount = questions.reduce((count, q) => 
    count + (q.isCorrect ? 1 : 0), 0);
  const totalAnswers = questions.length;
  const accuracy = totalAnswers > 0 ? Math.round((correctCount / totalAnswers) * 100) : 0;
  
  // Format time display from seconds
  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(mins).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
  };

  const filteredQuestions = showWrongOnly 
    ? questions.filter(q => !q.isCorrect)
    : questions;

  // Bookmark functionality removed

  const handleQuestionClick = (questionId) => {
    if (onQuestionClick) {
      onQuestionClick(questionId);
    }
  };

  return (
    <div className="results-container">
      <div className="results-content">
        <h1 className="results-title">Results</h1>
        
        {/* Performance Summary */}
        <div className="performance-summary">
          <div className="performance-metric">
            <div className="metric-content">
              <span>{accuracy}%</span>
              <div className="metric-label">Accuracy</div>
            </div>
          </div>
          {/* Day streak metric removed */}
          <div className="performance-metric">
            <div className="metric-content">
              <span>{formatTime(performanceData?.timeSpent || 0)}</span>
              <div className="metric-label">Time Spent</div>
            </div>
          </div>
        </div>
        
        <div className="filter-toggle">
          <input
            type="checkbox"
            id="wrongAnswers"
            checked={showWrongOnly}
            onChange={(e) => setShowWrongOnly(e.target.checked)}
          />
          <label htmlFor="wrongAnswers">Show wrong answers only</label>
        </div>

        <div className="questions-grid">
          {filteredQuestions.map((question, index) => (
            <div 
              key={question.id || question.question_id} 
              className="question-card"
              onClick={() => handleQuestionClick(question.id || question.question_id)}
            >
              <div className="card-header">
                <span className="question-title">Question {index + 1}</span>
              </div>
              
              <p className="question-text">
                {question.text || question.question}
              </p>

              <div className="status-indicators">
                {question.answers ? (
                  // If question has answers array (new_front format)
                  question.answers.map((answer, idx) => (
                    <div 
                      key={idx}
                      className={`status-icon ${answer.isCorrect ? 'correct' : 'incorrect'}`}
                    >
                      {answer.isCorrect ? (
                        <Check size={20} strokeWidth={2.5} />
                      ) : (
                        <X size={20} strokeWidth={2.5} />
                      )}
                    </div>
                  ))
                ) : (
                  // Fallback for old format
                  <div 
                    className={`status-icon ${question.isCorrect ? 'correct' : 'incorrect'}`}
                  >
                    {question.isCorrect ? (
                      <Check size={20} strokeWidth={2.5} />
                    ) : (
                      <X size={20} strokeWidth={2.5} />
                    )}
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>

        
        <div className="claim-xp-container">
          <button 
            className="claim-xp-btn"
            onClick={() => {
              if (onBackToDashboard) {
                onBackToDashboard();
              } else {
                navigate('/');
              }
            }}
          >
            Return to Dashboard
          </button>
          <button 
            className="instagram-feedback-btn"
            onClick={() => window.open('https://www.instagram.com/getmina.co/', '_blank')}
          >
            Send Feedback on Instagram
          </button>
        </div>
      </div>
    </div>
  );
};

export default ResultsReview;