import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../css/TrainingView.css';
// Stats component removed

const TrainingView = () => {
  // const navigate = useNavigate();
  const [stages, setStages] = useState([]);
  const [selectedStage, setSelectedStage] = useState(null);
  const [showStageDropdown, setShowStageDropdown] = useState(false);
  const [exams, setExams] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  useEffect(() => {
    // Fetch stages
    const fetchStages = async () => {
      setLoading(true);
      setError(null);
      try {
        const jwt = localStorage.getItem('jwt');
        const response = await fetch(`https://api.getmina.co/api/v1/panel/student/stages`, {
          headers: { 'Authorization': `Bearer ${jwt}` }
        });
        const data = await response.json();
        setStages(data);
        
        // Set first available stage as selected
        if (data.length > 0) {
          const firstIncompleteIndex = data.findIndex(stage => !stage.is_completed);
          const index = firstIncompleteIndex === -1 ? data.length - 1 : firstIncompleteIndex;
          setSelectedStage(data[index]);
          fetchExamsForStage(data[index].stage_id);
        }
      } catch (error) {
        console.error("Error fetching stages:", error);
        setError("Error fetching stages");
      } finally {
        setLoading(false);
      }
    };
    
    fetchStages();
  }, []);

  const fetchExamsForStage = async (stageId) => {
    setLoading(true);
    setError(null);
    try {
      const jwt = localStorage.getItem('jwt');
      const response = await fetch(`https://api.getmina.co/api/v1/panel/student/stage/${stageId}/exams`, {
        headers: { 'Authorization': `Bearer ${jwt}` }
      });
      const data = await response.json();
      setExams(data);
    } catch (error) {
      console.error("Error fetching exams:", error);
      setError("Error fetching exams");
    } finally {
      setLoading(false);
    }
  };
  
  const handleStageSelect = (stage) => {
    setSelectedStage(stage);
    fetchExamsForStage(stage.stage_id);
    setShowStageDropdown(false);
  };
  
  const getStageName = (stage, index) => {
    if (index === 0) return "Diagnostic Test";
    return `Chapter ${index}`;
  };
  
  // Get exam type based on section and category
  const getExamType = (exam) => {
    // For diagnostic exams (stage index 0)
    if (stages.indexOf(selectedStage) === 0) {
      return "Diagnostic";
    }
    // For regular exams
    if (exam.section) {
      return exam.section.charAt(0).toUpperCase() + exam.section.slice(1);
    }
    if (exam.is_final_exam) return "Review";
    return "Exercise";
  };
  
  // Calculate progress percentage
  const getProgress = (exam) => {
    if (exam.completed) return 100;
    return 0;
  };
  
  // Get exam path
  const getExamPath = (exam, stageIndex) => {
    // Check if the current stage is the diagnostic test stage
    // This is a safer approach than relying on index === 0
    const selectedStageIsFirst = stages.length > 0 && stages[0].stage_id === selectedStage?.stage_id;
    
    if (exam.completed) {
      return selectedStageIsFirst 
        ? `/review-personalized/diagnostic` 
        : `/review-personalized/${exam.exam_id}`;
    }
    
    return selectedStageIsFirst
      ? `/diagnostic-exam/${exam.exam_id}` // Use the correct exam_id for diagnostic exams
      : `/personalized-exam/${exam.exam_id}`;
  };

  return (
    <div className="training-view">
      <div className="dashboard-header">
        <h1>My Trainings</h1>
      </div>
      
      {!loading && stages.length > 0 && (
        <div className="session-selector">
          <button 
            className="session-button"
            onClick={() => setShowStageDropdown(!showStageDropdown)}
          >
            {selectedStage ? getStageName(selectedStage, stages.indexOf(selectedStage)) : "Select Chapter"}
            <svg className={`arrow-icon ${showStageDropdown ? 'rotated' : ''}`} width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6 9L12 15L18 9" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </button>
          
          <div className={`session-dropdown ${showStageDropdown ? 'show' : ''}`}>
            {[...stages].reverse().map((stage, index) => {
              // Reverse index for naming (diagnostic test should still be index 0)
              const originalIndex = stages.length - 1 - index;
              return (
                <button
                  key={stage.stage_id}
                  className={`session-option ${selectedStage && selectedStage.stage_id === stage.stage_id ? 'active' : ''}`}
                  onClick={() => handleStageSelect(stage)}
                >
                  {getStageName(stage, originalIndex)}
                </button>
              );
            })}
          </div>
        </div>
      )}
      
      {loading ? (
        <div className="loading-state">Loading trainings...</div>
      ) : error ? (
        <div className="error-state">{error}</div>
      ) : (
        <div className="exercises-grid">
          {exams.map((exam) => (
            <Link 
              key={exam.exam_id}
              onClick={(e) => {
                // Add debug logging before navigation
                console.log('Link clicked:', {
                  exam_id: exam.exam_id,
                  path: getExamPath(exam, stages.indexOf(selectedStage)),
                  stageIndex: stages.indexOf(selectedStage),
                  selectedStage
                });
              }}
              to={getExamPath(exam, stages.indexOf(selectedStage))}
              className="exercise-card"
            >
              <div className="exercise-card-content">
                <div className="exercise-info">
                  <span className="exercise-type">{getExamType(exam)}</span>
                  <h3 className="exercise-title">
                    {exam.categories || (exam.is_final_exam ? 'Quiz Review' : 'Exercise')}
                  </h3>
                </div>
                <div className="progress-bar">
                  <div 
                    className="progress-fill"
                    style={{ width: `${getProgress(exam)}%` }}
                  />
                </div>
              </div>
            </Link>
          ))}
          
          {exams.length === 0 && !loading && (
            <div className="empty-state">No trainings available for this chapter</div>
          )}
        </div>
      )}
    </div>
  );
};

export default TrainingView;