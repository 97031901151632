import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import '../css/QuestionView.css';
import mixpanel from '../mixpanel';
import { AlertTriangle } from 'lucide-react';
import QuestionLayout, { QUESTION_MODES } from '../components/common/Question/QuestionLayout';
import QuestionDisplay, { QUESTION_TYPES } from '../components/common/Question/components/QuestionDisplay';
import SessionComplete from '../components/common/Question/components/SessionComplete';
import ResultsReview from '../components/common/Question/components/ResultsReview';
import BeatLoader from 'react-spinners/BeatLoader';

const DIAGNOSTIC_QUESTIONS_API_URL = "https://api.getmina.co/api/v1/panel/diagnostic/get-questions";
const REPORT_ISSUE_API_URL = "https://api.getmina.co/api/v1/panel/report-issue";

function DiagnosticExam() {
  const SUBMIT_API_URL = "https://api.getmina.co/api/v1/panel/diagnostic/submit";
  const navigate = useNavigate();
  const { exam_id } = useParams();
  
  // State management
  const [responses, setResponses] = useState([]);
  const [content, setContent] = useState([]);
  const [currentContentIndex, setCurrentContentIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [timerMinutes, setTimerMinutes] = useState(0);
  const [timerSeconds, setTimerSeconds] = useState(0);
  const [showReportModal, setShowReportModal] = useState(false);
  const [selectedIssue, setSelectedIssue] = useState('');
  const [otherIssueText, setOtherIssueText] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showExitModal, setShowExitModal] = useState(false);
  const [questionStartTime, setQuestionStartTime] = useState(null);
  const [viewState, setViewState] = useState('question'); // 'question', 'complete', 'results'
  const [examResults, setExamResults] = useState(null);

  const examIdNumber = Number(exam_id);
  const hasFetchedQuestions = useRef(false);

  // Format time for display
  const formatTime = useCallback(() => {
    return `${String(timerMinutes).padStart(2, '0')}:${String(timerSeconds).padStart(2, '0')}`;
  }, [timerMinutes, timerSeconds]);
  
  // Get total time in seconds
  const getTotalTimeInSeconds = useCallback(() => {
    return timerMinutes * 60 + timerSeconds;
  }, [timerMinutes, timerSeconds]);

  // Fetch questions from API
  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        setLoading(true);
        const jwt = localStorage.getItem('jwt');
        const response = await fetch(`${DIAGNOSTIC_QUESTIONS_API_URL}/${examIdNumber}`, {
          method: "GET",
          headers: {
            'Authorization': `Bearer ${jwt}`
          },
        });
        const data = await response.json();
        console.log("Diagnostic questions:", data);
        setContent(data.map(question => ({ 
          ...question, 
          selectedAnswer: null, 
          isAnswered: false 
        })));
        setLoading(false);
      } catch (error) {
        console.error("Error fetching questions:", error);
        setError("Error fetching questions");
        setLoading(false);
      }
    };

    if (!hasFetchedQuestions.current) {
      fetchQuestions();
      hasFetchedQuestions.current = true;
    }
  }, [examIdNumber]);

  // Set question start time for tracking duration
  useEffect(() => {
    setQuestionStartTime(Date.now());
  }, [currentContentIndex]);

  // Ref to store timer interval ID
  const timerIntervalRef = useRef(null);
  
  // Timer effect
  useEffect(() => {
    // Clear any existing timer
    if (timerIntervalRef.current) {
      clearInterval(timerIntervalRef.current);
    }
    
    // Start new timer only if in question view and content is loaded
    if (viewState === 'question' && !loading && content.length > 0) {
      console.log("Starting timer");
      timerIntervalRef.current = setInterval(() => {
        setTimerSeconds(prev => {
          if (prev === 59) {
            setTimerMinutes(min => min + 1);
            return 0;
          }
          return prev + 1;
        });
      }, 1000);
    } else {
      console.log("Timer not started, viewState:", viewState);
    }

    // Cleanup function
    return () => {
      if (timerIntervalRef.current) {
        clearInterval(timerIntervalRef.current);
        console.log("Timer cleared");
      }
    };
  }, [viewState, loading, content.length]);
  
  // Format text to handle newlines and spacing
  const formatText = useCallback((text) => {
    if (!text || text === '0') return '';
    return text.replace(/\\n/g, '\n');
  }, []);

  // Handle answer selection
  const handleAnswerSelection = (index) => {
    const timeTaken = Math.round((Date.now() - questionStartTime) / 1000);

    // Update content array with the selected answer
    const updatedContent = content.map((question, idx) =>
      idx === currentContentIndex ? { ...question, selectedAnswer: index } : question
    );
    setContent(updatedContent);

    // Update responses array with the new answer
    const questionId = content[currentContentIndex].question_id;
    const selectedAnswer = content[currentContentIndex].choices.choices[index];

    const newResponses = responses.filter(response => response.question_id !== questionId);
    newResponses.push({
      question_id: questionId,
      selected_answer: selectedAnswer,
      time_taken: timeTaken
    });
    setResponses(newResponses);
  };

  // Navigate to next question
  const handleNextQuestion = () => {
    if (currentContentIndex < content.length - 1) {
      setCurrentContentIndex(currentContentIndex + 1);
      setQuestionStartTime(Date.now());
    } else {
      handleSubmit();
    }
  };

  // Go back to previous question
  const handlePreviousQuestion = () => {
    if (currentContentIndex > 0) {
      setCurrentContentIndex(currentContentIndex - 1);
    }
  };

  // Handle exit button click
  const handleExit = (e) => {
    e?.preventDefault();
    setShowExitModal(true);
  };

  // Show session complete screen
  // const showCompleteScreen = (responseData) => {
  //   // Calculate correct answers for display in complete screen
  //   const correctAnswers = responses.reduce((count, response) => {
  //     const question = content.find(q => q.question_id === response.question_id);
  //     if (!question) return count;
      
  //     const correctAnswer = question.correct_answer || "";
  //     return response.selected_answer === correctAnswer ? count + 1 : count;
  //   }, 0);
    
  //   setExamResults({
  //     totalQuestions: content.length,
  //     totalAnswered: responses.length,
  //     correctAnswers: correctAnswers,
  //     score: Math.round((correctAnswers / content.length) * 100),
  //     timeSpent: getTotalTimeInSeconds(),
  //     data: responseData
  //   });
  //   setViewState('complete');
  // };

  // Handle view results button click
  const handleViewResults = () => {
    setViewState('results');
  };

  // Format result data for the results review screen
  const prepareResultsData = () => {
    console.log("Preparing results data with responses:", responses);
    
    return content.map(question => {
      const response = responses.find(r => r.question_id === question.question_id);
      console.log(`Question ${question.question_id} response:`, response);
      
      // If no response, mark as incorrect
      if (!response) {
        console.log(`No response found for question ${question.question_id}`);
        return {
          id: question.question_id,
          question: question.question,
          instruction: question.instruction,
          isCorrect: false,
          selectedAnswer: null,
          selectedAnswerIndex: null,
          isBookmarked: false
        };
      }
      
      const selectedAnswerIndex = question.choices.choices.findIndex(c => c === response.selected_answer);
      console.log(`Selected answer index for question ${question.question_id}:`, selectedAnswerIndex);
      
      // Get correct answer index for comparison
      const correctAnswerText = question.correct_answer || "";
      const correctAnswerIndex = question.choices.choices.findIndex(c => c === correctAnswerText);
      console.log(`Correct answer index for question ${question.question_id}:`, correctAnswerIndex, "Correct answer:", correctAnswerText);
      
      // Check if the selected answer matches the correct answer
      const isCorrect = response.selected_answer === correctAnswerText;
      console.log(`Is correct for question ${question.question_id}:`, isCorrect);
      
      return {
        id: question.question_id,
        question: question.question,
        instruction: question.instruction,
        isCorrect: isCorrect,
        selectedAnswer: response.selected_answer,
        selectedAnswerIndex: selectedAnswerIndex >= 0 ? selectedAnswerIndex : null,
        isBookmarked: false
      };
    });
  };

  // Handle back to dashboard
  const handleBackToDashboard = () => {
    navigate('/'); // Navigate directly to the dashboard (home) instead of diagnostic stats
  };

  // Submit exam answers
  const handleSubmit = async () => {
    if (currentContentIndex === content.length - 1 && !isSubmitting) {
      setIsSubmitting(true);
      
      // Explicitly stop the timer when submitting
      if (timerIntervalRef.current) {
        clearInterval(timerIntervalRef.current);
        timerIntervalRef.current = null;
        console.log("Timer stopped at submission");
      }
      
      const requestBody = { diagnostic_exam_id: exam_id, responses: responses };
      console.log("Submitting diagnostic exam:", requestBody);
      
      try {
        const jwt = localStorage.getItem('jwt');
        const response = await fetch(SUBMIT_API_URL, {
          method: "POST",
          headers: { 
            'Authorization': `Bearer ${jwt}`, 
            "Content-Type": "application/json" 
          },
          body: JSON.stringify(requestBody)
        });
        
        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(`Failed to submit exam: ${response.status} ${errorText}`);
        }

        // Just get a basic success response - don't try to parse JSON
        mixpanel.track('Exam Completed', {
          exam_id: exam_id,
          exam_type: "diagnostic_exam"
        });

        // Fetch the correct answers for each question if not already available
        // This step ensures we have all correct answers available
        let updatedContent = [...content];
        
        // Process content to include correct answers where missing
        updatedContent = updatedContent.map(question => {
          // If correct_answer exists and is not empty, use it
          if (question.correct_answer && question.correct_answer !== "") {
            return question;
          }
          
          // Try to determine correct answer from choices if possible
          // This is a fallback if correct_answer is not set
          try {
            // Assumption: correct answer might be marked in choices
            // You may need to adapt this logic based on your data structure
            return {
              ...question,
              correct_answer: question.choices.choices[0] // Use first choice as fallback
            };
          } catch (err) {
            console.warn(`Could not determine correct answer for question ${question.question_id}`, err);
            return question;
          }
        });
        
        // Replace content with updated version
        setContent(updatedContent);
        
        // Calculate correct answers with the updated content
        const correctAnswers = responses.reduce((count, response) => {
          const question = updatedContent.find(q => q.question_id === response.question_id);
          if (!question) return count;
          
          const correctAnswer = question.correct_answer || "";
          return response.selected_answer === correctAnswer ? count + 1 : count;
        }, 0);
        
        const totalQuestions = updatedContent.length;
        const score = Math.round((correctAnswers / totalQuestions) * 100);
        
        console.log('Calculated score:', {
          correctAnswers,
          totalQuestions,
          score,
          responses: responses.length
        });
        
        // Set exam results with score - do this before changing view state
        setExamResults({
          totalQuestions: totalQuestions,
          totalAnswered: responses.length,
          correctAnswers: correctAnswers,
          score: score,
          timeSpent: getTotalTimeInSeconds()
        });
        
        // Small delay to ensure state is updated before changing view
        setTimeout(() => {
          // Show the session complete screen
          setViewState('complete');
        }, 100);
      } catch (error) {
        console.error("Failed to submit exam:", error.message);
        alert(`Failed to submit exam: ${error.message}`);
        setIsSubmitting(false);
      }
    }
  };

  // Open report issue modal
  const handleReportIssue = () => {
    setShowReportModal(true);
  };

  // Submit issue report
  const handleSubmitIssue = async () => {
    try {
      const jwt = localStorage.getItem('jwt');
      const issueMessage = selectedIssue === "Other" ? otherIssueText : selectedIssue;
      
      const response = await fetch(REPORT_ISSUE_API_URL, {
        method: "POST",
        headers: {
          'Authorization': `Bearer ${jwt}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          question_id: content[currentContentIndex]?.question_id,
          issue_message: issueMessage,
        })
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to submit issue: ${response.status} ${errorText}`);
      }

      alert("Issue reported successfully!");
      setShowReportModal(false);
      setSelectedIssue('');
      setOtherIssueText('');
    } catch (error) {
      console.error("Failed to submit issue:", error.message);
    }
  };

  // Loading state
  if (loading) {
    return (
      <div className="loading-container">
        <BeatLoader color="#3E00EF" />
        <p>Loading diagnostic exam...</p>
      </div>
    );
  }

  // Error state
  if (error) {
    return <div className="error-message">{error}</div>;
  }

  // No questions state
  if (content.length === 0 || !content[currentContentIndex]) {
    return <div className="error-message">No questions available.</div>;
  }
  
  // Render session complete screen
  if (viewState === 'complete') {
    return (
      <SessionComplete
        totalXP={100}
        score={examResults?.score || 0}
        timeInSeconds={getTotalTimeInSeconds()}
        onViewResults={handleViewResults}
        buttonText="See results"
      />
    );
  }

  // Render results review screen
  if (viewState === 'results') {
    return (
      <ResultsReview
        questions={prepareResultsData()}
        performanceData={{
          streak: 0,
          timeSpent: getTotalTimeInSeconds()
        }}
        onBackToDashboard={handleBackToDashboard}
      />
    );
  }

  // Get current question
  const currentQuestion = content[currentContentIndex];
  const { script, question, instruction, choices } = currentQuestion;
  const hasScript = script && script !== "0";
  const hasAnswer = currentQuestion.selectedAnswer !== null;
  
  // Prepare options for the QuestionDisplay component
  const options = [
    ...choices.choices.map((choice, index) => ({ id: index, text: choice }))
  ];

  return (
    <>
      <QuestionLayout
        timer={formatTime()}
        totalQuestions={content.length}
        currentQuestion={currentContentIndex + 1}
        onExit={handleExit}
        isAnswered={false} // Diagnostic exams don't show correct/incorrect immediately
        mode={QUESTION_MODES.SOLVING}
        footer={
          <>
            <button 
              className="report-btn" 
              onClick={handleReportIssue}
            >
              <AlertTriangle size={20} />
              <span className="report-text">Report Issue</span>
            </button>
            
            <div className="navigation-buttons">
              <button 
                className="nav-btn previous" 
                disabled={currentContentIndex === 0}
                onClick={handlePreviousQuestion}
              >
                Previous
              </button>
              
              <button 
                className="nav-btn next" 
                onClick={handleNextQuestion}
                disabled={!hasAnswer || isSubmitting}
              >
                {currentContentIndex === content.length - 1 ? 
                  (isSubmitting ? "Submitting..." : "Finish") : 
                  "Next"}
              </button>
            </div>
          </>
        }
      >
        <QuestionDisplay
          type={QUESTION_TYPES.SINGLE}
          title={`Question ${currentContentIndex + 1}`}
          instruction={instruction}
          script={hasScript ? script : null}
          questionText={question}
          options={options}
          selectedAnswer={currentQuestion.selectedAnswer}
          isAnswered={false}
          onAnswerSelect={handleAnswerSelection}
          formatText={formatText}
        />
      </QuestionLayout>

      {/* Exit Modal */}
      {showExitModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3>Are you sure you want to exit?</h3>
            <p>Your progress won't be saved.</p>
            <div className="modal-buttons">
              <button 
                className="modal-button secondary" 
                onClick={() => setShowExitModal(false)}
              >
                No
              </button>
              <button 
                className="modal-button primary" 
                onClick={() => {
                  mixpanel.track('Cancel Exam', { 
                    exam_id: exam_id, 
                    question_id: currentQuestion?.question_id, 
                    question_number: currentContentIndex + 1 
                  });
                  navigate('/');
                }}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Report Issue Modal */}
      {showReportModal && (
        <div className="modal-overlay">
          <div className="report-modal">
            <h3>
              Report Issue
              <button onClick={() => setShowReportModal(false)}>×</button>
            </h3>
            <h4>What's wrong?</h4>
            <div className="report-options">
              {[
                { value: 'None of the options is correct', label: 'None of the options is correct' },
                { value: 'Question has incorrect or missing data', label: 'Question has incorrect or missing data' },
                { value: 'Questions/Options/Infographics are not visible properly or missing', label: 'Questions/Options/Infographics are not visible properly or missing' },
                { value: 'Question or option has a typo', label: 'Question or option has a typo' },
                { value: 'The question is confusing or unclear', label: 'The question is confusing or unclear' },
                { value: 'Other', label: 'Other' }
              ].map(option => (
                <label key={option.value} className="report-option">
                  <input 
                    type="radio" 
                    name="issue" 
                    value={option.value}
                    checked={selectedIssue === option.value}
                    onChange={(e) => setSelectedIssue(e.target.value)}
                  />
                  {option.label}
                </label>
              ))}
            </div>
            
            {selectedIssue === 'Other' && (
              <div className="other-description">
                <label>Description of issue:</label>
                <textarea
                  value={otherIssueText}
                  onChange={(e) => setOtherIssueText(e.target.value)}
                  placeholder="Please describe the issue..."
                  rows={4}
                />
              </div>
            )}
            
            <button 
              className="submit-issue" 
              onClick={handleSubmitIssue}
              disabled={selectedIssue === 'Other' && !otherIssueText.trim()}
            >
              Submit Issue
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default DiagnosticExam;