import React, { useEffect, useState } from 'react';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import './css/design-system.css';
import './css/global-style-override.css';
import './css/mobile-spacing-fix.css';

// Components
import Sidebar from './components/Sidebar';
import AdBlockDetector from './components/AdBlockDetector';

// Pages
import DashboardView from './components/DashboardView';
import StudyPlan from './study_plan/StudyPlan';
import TrainingView from './study_plan/TrainingView';
import DiagnosticStats from './study_plan/DiagnosticStats';
import DiagnosticExam from './study_plan/DiagnosticExam';
import PersonalizedExam from './study_plan/PersonalizedExam';
import ReviewPersonalizedExam from './study_plan/ReviewPersonalized';
// import ReviewQuestion from './study_plan/ReviewQuestion';
import ModernReviewQuestion from './study_plan/ModernReviewQuestion';
import Stats from './study_plan/Stats';

// import Home from './selected_study/Home';
import Exam from './selected_study/Exam';
import ReviewExam from './selected_study/ReviewExam';
import ReviewExamQuestion from './selected_study/ReviewExamQuestion';

import AskMina from './AskMina';
import AskMinaHome from './AskMinaHome';

import Login from './Login';
import Signup from './onboarding/SignUp';
import EmailVerification from './onboarding/EmailVerification';
import ForgotPassword from './onboarding/forgotpassword';
import ResetPassword from './onboarding/resetpassword';
import JLPTExperienceStep from './onboarding/JLPTExperienceStep';
import TargetLevelStep from './onboarding/TargetLevelStep';
import LevelTestIntro from './onboarding/LevelTestIntro';

// Admin Dashboard
import UsersListPage from './dashboard/UsersListPage';
import DiagnosesExamDetail from './dashboard/DiagnosesExamDetail';
import CreateStudyPlan from './dashboard/CreateStudyPlan';
import UserExamsPage from './dashboard/UserExamsPage';
import DiagnosticExams from './dashboard/DiagnosticExams';
import DirectAdmin from './dashboard/DirectAdmin';
import DiagnosticExamResultsPage from './dashboard/DiagnosticExamsResultsPage';
import PersonalizedExamResultsPage from './dashboard/PersonalizedExamsResultsPage';

// Settings
import SettingsView from './settings/SettingsView';

// Admin Portal
import AdminPortal from './components/AdminPortal';
import './css/AdminPortal.css';

// Standalone admin access
import AdminAccess from './standalone/AdminAccess';

// Utilities and other
import './css/App.css';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import MyCheckoutComponent from './MyCheckoutComponent';
import QuizOfTheDay from './components/QuizOfTheDay';

// Use test key for now - matches the one in MyCheckoutComponent
const stripePromise = loadStripe('pk_test_51PCLezBQK9NeVKEQ9UZaCLagoap3lORt7iIs0j2mZAmnVBCHh1D3hYr8h9A7UlXVRG8w1PVdaA92qxLDZjOuLRCn002sOLsGHy');

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userStatus, setUserStatus] = useState('');
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isOnboarding, setIsOnboarding] = useState(false);
  const [isDirectAdminAccess, setIsDirectAdminAccess] = useState(false);
  
  useEffect(() => {
    // First check if trying to access admin routes directly
    const currentPath = window.location.pathname;
    const adminRoutes = [
      '/DiagnosticExams', 
      '/direct-admin-access',
      '/admin-stats-7c39a',
      '/admin-access-mina',
      '/teacher-panel-access',
      '/diagnostic-dashboard',
      '/account-info',
      '/user-profile'
    ];
    
    // If direct admin access, set flag to bypass authentication
    if (adminRoutes.some(route => currentPath === route)) {
      console.log('Direct admin access detected:', currentPath);
      setIsDirectAdminAccess(true);
      setIsAuthenticated(true);
      return; // Skip other checks
    }
    
    // Check current URL to detect onboarding routes
    const onboardingRoutes = ['/jlpt-experience', '/target-level', '/level-test', '/email-verification'];
    const isOnboardingRoute = onboardingRoutes.some(route => currentPath.startsWith(route));
    
    setIsOnboarding(isOnboardingRoute);
    
    // Check for JWT in local storage
    const jwt = localStorage.getItem('jwt');
    
    if (jwt) {
      const checkUserStatus = async () => {
        try {
          const response = await fetch("https://api.getmina.co/api/v1/auth/me", {
            headers: { 'Authorization': `Bearer ${jwt}` },
            method: "GET",
          });
          
          if (response.ok) {
            const userData = await response.json();
            setUserStatus(userData.status);
          }
        } catch (error) {
          console.error("Error checking user status:", error);
        }
      };
      
      setIsAuthenticated(true);
      checkUserStatus();
    } else {
      setIsAuthenticated(false);
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('jwt');
    setIsAuthenticated(false);
    window.location.reload();
  };
  
  const handleMobileMenuClose = () => {
    setIsMobileMenuOpen(false);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  // Special case for direct admin access
  if (isDirectAdminAccess) {
    return (
      <Router>
        <Routes>
          <Route path="/DiagnosticExams" element={<DirectAdmin />} />
          <Route path="/direct-admin-access" element={<DirectAdmin />} />
          <Route path="/admin-stats-7c39a" element={<DirectAdmin />} />
          <Route path="/admin-access-mina" element={<DirectAdmin />} />
          <Route path="/teacher-panel-access" element={<DirectAdmin />} />
          <Route path="/diagnostic-dashboard" element={<DirectAdmin />} />
          <Route path="/admin" element={<DirectAdmin />} />
          <Route path="/admin-standalone" element={<AdminAccess />} />
          <Route path="/account-info" element={<AdminPortal />} />
          <Route path="/user-profile" element={<AdminPortal />} />
          <Route path="*" element={<Navigate to="/DiagnosticExams" />} />
        </Routes>
      </Router>
    );
  }

  // When user is in onboarding flow or not authenticated
  if (!isAuthenticated || isOnboarding) {
    return (
      <Router>
        <Routes>
          <Route path="/login" element={<Login setIsAuthenticated={setIsAuthenticated} />} />
          <Route path="/register" element={<Signup />} />
          <Route path="/email-verification" element={<EmailVerification />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/jlpt-experience" element={<JLPTExperienceStep />} />
          <Route path="/target-level" element={<TargetLevelStep />} />
          <Route path="/level-test" element={<LevelTestIntro />} />
          {/* Add diagnostic exam route for onboarding flow */}
          <Route path="/diagnostic-exam/:exam_id" element={<DiagnosticExam />} />
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      </Router>
    );
  }

  return (
    <Router>
      <div className="App">
        <Sidebar 
          isMobileMenuOpen={isMobileMenuOpen}
          onMobileMenuClose={handleMobileMenuClose}
          onMobileMenuToggle={toggleMobileMenu}
          onLogout={handleLogout}
          onGetFullAccess={() => window.location.href = 'https://buy.stripe.com/5kA154aaHaVcbGU145'}
          userStatus={userStatus}
        />
        
        <AdBlockDetector />
        
        <div className="main-content">
          <Routes>
            {/* Main routes - match exactly what's in the sidebar navItems */}
            <Route path="/" element={<DashboardView />} />
            <Route path="/study_plan" element={<TrainingView />} />
            <Route path="/ask-mina" element={<AskMinaHome />} />
            <Route path="/settings" element={<SettingsView />} />
            
            {/* Hidden admin portal with innocent name */}
            <Route path="/account-info" element={<AdminPortal />} />
            <Route path="/user-profile" element={<AdminPortal />} />
            
            {/* Legacy and other routes */}
            <Route path="/study_plan/:id" element={<StudyPlan />} />
            <Route path="/selected_study" element={<StudyPlan />} />
            <Route path="/dashboard" element={<DashboardView />} />
            <Route path="/stats" element={<Stats />} />
            <Route path="/diagnostic-stats" element={<DiagnosticStats />} />
            <Route path="/exam/:exam_id" element={<Exam />} />
            <Route path="/personalized-exam/:exam_id" element={<PersonalizedExam />} />
            <Route path="/diagnostic-exam/:exam_id" element={<DiagnosticExam />} />
            <Route path="/askmina" element={<AskMina />} />
            <Route path="/review-exam/:exam_id" element={<ReviewExam />} />
            <Route path="/review-personalized/:exam_id" element={<ReviewPersonalizedExam />} />
            <Route path="/review-question/:exam_id" element={<ModernReviewQuestion />} />
            <Route path="/review-exam-question/:exam_id" element={<ReviewExamQuestion />} />
            
            {/* Admin routes */}
            <Route path="/exam/:examId/users" element={<UsersListPage />} />
            <Route path="/person/:id/exam-results" element={<DiagnosesExamDetail />} />
            <Route path="/DiagnosticExams" element={<DiagnosticExams />} />
            {/* Create a second secret route with a different name */}
            <Route path="/admin-stats-7c39a" element={<DiagnosticExams />} />
            <Route path="/user/:userId/exams" element={<UserExamsPage />} />
            <Route path="/diagnostic-exam-results/:userId/:examId" element={<DiagnosticExamResultsPage />} />
            <Route path="/personalized-exam-results/:userId/:examId" element={<PersonalizedExamResultsPage />} />
            <Route path="/create-study-plan/:userId" element={<CreateStudyPlan />} />
            
            {/* Other routes */}
            <Route path="/quiz-of-the-day" element={<QuizOfTheDay />} />
            <Route path="/checkout" element={
              <Elements stripe={stripePromise}>
                <MyCheckoutComponent />
              </Elements>
            } />
            
            {/* Admin dashboard routes with secret names */}
            <Route path="/admin-access-mina" element={<DirectAdmin />} />
            <Route path="/teacher-panel-access" element={<DirectAdmin />} />
            <Route path="/diagnostic-dashboard" element={<DirectAdmin />} />
            <Route path="/admin" element={<DirectAdmin />} />
            
            {/* Fallback route */}
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;